import { Box, Slider, Typography } from '@mui/material'
import './riskLevel.scss'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

export const RiskLevelSlider = ({ type }) => {
  const { t } = useTranslation(['common'])
  const RiskSlider = styled(Slider)(({ background }) => ({
    height: 10,
    padding: 0,
    '& .MuiSlider-track': {
      border: 'none',
      background,
    },
    '& .MuiSlider-thumb': {
      display: 'none',
    },
    '& .MuiSlider-rail': {
      color: '#ECEFED',
      border: '1px solid #1C282026',
      height: 8,
    },
  }))

  switch (type) {
    case 'VERY_HIGH':
      return (
        <Box>
          <Typography variant="h4">{t('common:veryHighRisk')}</Typography>
          <RiskSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={100}
            background="linear-gradient(90deg, #FF2B1B, #420098)"
          />
        </Box>
      )
    case 'HIGH':
      return (
        <Box>
          <Typography variant="h4">{t('common:highRisk')}</Typography>
          <RiskSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={80}
            background="linear-gradient(90deg, #FFB800, #FF2B1B, #BB0647)"
          />
        </Box>
      )
    case 'MEDIUM':
      return (
        <Box>
          <Typography variant="h4">{t('common:mediumRisk')}</Typography>
          <RiskSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={60}
            background="linear-gradient(90deg, #FFB800, #FF2B1B)"
          />
        </Box>
      )
    case 'LOW':
      return (
        <Box>
          <Typography variant="h4">{t('common:lowRisk')}</Typography>
          <RiskSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={40}
            background="linear-gradient(90deg, #00B85F, #00FF84)"
          />
        </Box>
      )
    case 'VERY_LOW':
      return (
        <Box>
          <Typography variant="h4">{t('common:noRisk')}</Typography>
          <RiskSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={20}
            background="linear-gradient(90deg, #00B85F, #00FF84)"
          />
        </Box>
      )
    case 'NOT_DEFINED':
      return (
        <Box>
          <Typography variant="h4">{t('common:notDefined')}</Typography>
          <RiskSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={15}
            background="linear-gradient(90deg, #eee, #000)"
          />
        </Box>
      )
    default:
      return
  }
}
