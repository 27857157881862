import './riskMatrix.scss'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Modal,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Chip,
  TextField,
  Typography,
  Paper,
  Divider,
  Fab,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import DeleteIcon from '@mui/icons-material/Delete'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { RenderTooltip } from '../../../components/utilities/RenderTooltip'

const targets = ['KYB']

const kybKeys = [
  {
    label: 'input1',
    key: 'CNAE',
    comparators: ['is in'],
    type: 'list',
  },
  {
    label: 'input2',
    key: 'Lawsuits',
    comparators: ['contains'],
    type: 'string',
    default: 'Criminal',
  },
  {
    label: 'input3',
    key: 'Lawsuits dates (years)',
    comparators: ['less than'],
    type: 'number',
  },
  {
    label: 'input4',
    key: 'Kyc',
    comparators: ['equal'],
    type: 'string',
    default: 'IS PEP',
  },
  {
    label: 'input5',
    key: 'CNPJ Status',
    comparators: ['different than'],
    type: 'string',
    default: 'Ativa',
  },
  {
    label: 'input6',
    key: 'Natureza Juridica',
    comparators: ['equal'],
    type: 'number',
  },
  {
    label: 'input7',
    key: 'CNPJ registration date (months)',
    comparators: ['less than'],
    type: 'number',
  },
  {
    label: 'input8',
    key: 'Shareholder country',
    comparators: ['is in'],
    type: 'list',
  },
  {
    label: 'input9',
    key: 'Shareholder status',
    comparators: ['equal'],
    type: 'string',
    default: 'DENIED',
  },
  {
    label: 'input10',
    key: 'Adverse Media',
    comparators: ['contains'],
    type: 'string',
    default: 'Reclame Aqui',
  },
  {
    label: 'input11',
    key: 'Sanctions',
    comparators: ['contains'],
    type: 'string',
    default: 'Positive match',
  },
]

const RiskMatrixCreation = () => {
  const riskMatrixs = useSelector((state) => state.riskMatrix?.riskMatrixs)
  const { accessToken } = useSelector((state) => state.login)
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [target, setTarget] = useState('KYB')
  const [rmId, setRmId] = useState('')

  const [ranges, setRanges] = useState({
    low: { min: 0, max: 250 },
    medium: { min: 260, max: 500 },
    high: { min: 510, max: 750 },
    veryHigh: { min: 760, max: 1000 },
  })

  const [selectedKey, setSelectedKey] = useState('')
  const [key, setKey] = useState('')
  const [comparator, setComparator] = useState('')
  const [value, setValue] = useState('')
  const [valueList, setValueList] = useState([])
  const [riskScore, setRiskScore] = useState('')
  const [keys, setKeys] = useState([])

  const [askForAnother, setAskForAnother] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])

  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)

  useEffect(() => {
    if (id && keys.length === 0) {
      let item = riskMatrixs?.data?.filter((r) => r.name === id)
      if (item.length !== 0) {
        setKeys(item[0].params)
        setName(item[0].name)
        setDescription(item[0].description)
        setTarget(item[0].target)
        setRmId(item[0]._id)
        setRanges(
          item[0].riskLevelRanges ?? {
            low: { min: 0, max: 250 },
            medium: { min: 260, max: 500 },
            high: { min: 510, max: 750 },
            veryHigh: { min: 760, max: 1000 },
          },
        )
      }
    }
  }, [riskMatrixs, id])

  const handleClose = () => {
    setName('')
    setDescription('')
    setAskForAnother(false)
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const createMatrix = () => {
    setIsLoading(true)
    let data = {
      name,
      description,
      target,
      rmKeyTag: 'TYPE1',
      params: keys.map((item) => {
        return { ...item, riskScore: parseInt(item.riskScore) }
      }),
      riskLevelRanges: ranges,
    }

    const urlRisk = `${process.env.REACT_APP_BASEURL}/riskmatrix`
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    fetch(urlRisk, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          if (res.data && typeof res.data === 'string') {
            handleFetch(true, res.data)
          } else if (res.data?.message) {
            handleFetch(true, res.data?.message || 'Risk Matrix creation error')
          }
          setIsLoading(false)
        } else {
          handleFetch(false, res.message)
          setTimeout(() => {
            setIsLoading(false)
          }, 2000)
          setAskForAnother(true)
        }
      })
      .catch((error) => {
        console.error('[CREATE RISK MATRIX ERROR] --> ', error)
        setIsLoading(false)
        handleFetch(true, error.message ? error.message : 'Risk Matrix creation error')
      })
  }

  const updateMatrix = () => {
    setIsLoading(true)
    let data = {
      name,
      description,
      target,
      rmKeyTag: 'TYPE1',
      params: keys.map((item) => {
        return { ...item, riskScore: parseInt(item.riskScore) }
      }),
      riskLevelRanges: ranges,
    }

    const urlRisk = `${process.env.REACT_APP_BASEURL}/riskmatrix/${rmId}`
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    fetch(urlRisk, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          if (res.data && typeof res.data === 'string') {
            handleFetch(true, res.data)
          }
          setIsLoading(false)
        } else {
          handleFetch(false, res.message)
          setTimeout(() => {
            setIsLoading(false)
          }, 2000)
          navigate('/admin/riskMatrix')
        }
      })
      .catch((error) => {
        console.error('[CREATE RISK MATRIX ERROR] --> ', error)
        setIsLoading(false)
        handleFetch(true, error.message ? error.message : 'Risk Matrix creation error')
      })
  }

  const handleResetKeys = () => {
    setSelectedKey('')
    setKey('')
    setComparator('')
    setValue('')
    setValueList('')
    setRiskScore('')
  }

  const handleAddItem = () => {
    let keysTemp = [...keys]
    keysTemp.push({ key, comparator, value: selectedKey?.type === 'list' ? valueList : value, riskScore })
    setKeys([...keysTemp])
    handleResetKeys()
  }

  const handleDeleteItem = (idx) => {
    let keysTemp = [...keys]
    keysTemp.splice(idx, 1)
    setKeys([...keysTemp])
  }

  const adjustRanges = (key, minOrMax, value) => {
    let updatedRanges = {
      low: { ...ranges.low },
      medium: { ...ranges.medium },
      high: { ...ranges.high },
      veryHigh: { ...ranges.veryHigh },
    }
    const valueNumber = Number(value)

    if (minOrMax === 'min') {
      if ((key === 'low' && valueNumber >= 0) || key !== 'low') updatedRanges[key].min = valueNumber
      if (key !== 'low') {
        const previousKey = Object.keys(updatedRanges)[Object.keys(updatedRanges).indexOf(key) - 1]
        updatedRanges[previousKey].max = valueNumber - 1
      }
    } else {
      // 'max'
      updatedRanges[key].max = valueNumber
      if (key !== 'veryHigh') {
        const nextKey = Object.keys(updatedRanges)[Object.keys(updatedRanges).indexOf(key) + 1]
        updatedRanges[nextKey].min = valueNumber + 1
      }
    }

    setRanges(updatedRanges)
  }

  const totalRange = 100
  const calculateWidth = (rangeKey) => {
    const width = ((ranges[rangeKey].max - ranges[rangeKey].min) / totalRange) * 100
    return `${width}%`
  }

  const rangeColors = {
    low: '#19db55',
    medium: '#ffa641',
    high: '#ff8a00',
    veryHigh: '#ff2b1b',
  }

  return (
    <Box className="component-wrapper">
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <Box>
        <Box className="component-title">
          <Typography variant="h2">
            {id ? t('common:update') : t('common:create')} {t('common:riskMatrix')}
          </Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Box className="matrix-manual-divider">
        <Paper elevation={0} variant="rootOutlined" sx={{ marginRight: '1rem', padding: '1.5rem 1rem 1.5rem 1.5rem' }}>
          <Box className="matrix-manual-overflow">
            <Box sx={{ marginBottom: '1.5rem' }}>
              <Typography variant="title">{t('common:information')}</Typography>
            </Box>

            <Box className="matrix-manual-wrapper">
              <Box className="matrix-manual-flex">
                <Box className="matrix-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:name')}</InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                  </Box>
                  <TextField
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder={`${t('common:insert')} ${t('common:name')}`}
                  />
                </Box>

                <Box className="matrix-manual-input">
                  <InputLabel htmlFor="description">{t('common:description')}</InputLabel>
                  <TextField
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    placeholder={`${t('common:insert')} ${t('common:description')}`}
                  />
                </Box>

                <Box className="matrix-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:targetEntity')}</InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                  </Box>
                  <Select
                    displayEmpty
                    fullWidth
                    size="small"
                    value={target}
                    onChange={(e) => setTarget(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:targetEntity')}`}</Typography>
                    </MenuItem>
                    {targets.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Divider />

              <Box sx={{ gridColumn: '1 / 4' }}>
                <Typography variant="title">{t('common:riskLevelScoreTitle')}</Typography>
              </Box>

              <Box className="matrix-manual-flex">
                {Object.keys(ranges).map((key) => (
                  <Box key={key} className="matrix-manual-input">
                    <InputLabel>{t(`common:${key}`)}</InputLabel>
                    <TextField
                      fullWidth
                      type="number"
                      value={ranges[key].min}
                      onChange={(e) => adjustRanges(key, 'min', e.target.value)}
                    />
                    <TextField
                      fullWidth
                      type="number"
                      value={ranges[key].max}
                      onChange={(e) => adjustRanges(key, 'max', e.target.value)}
                    />
                  </Box>
                ))}
              </Box>

              <Box className="matrix-level-bar">
                {Object.keys(ranges).map((key) => (
                  <Box
                    className="matrix-level-bar-item"
                    key={key}
                    style={{
                      width: calculateWidth(key),
                      backgroundColor: rangeColors[key],
                      transition: 'width 0.3s ease',
                    }}
                  >
                    <Typography variant="title2">{t(`common:${key}`)}</Typography>
                  </Box>
                ))}
              </Box>

              <Divider />

              <Box sx={{ gridColumn: '1 / 4' }}>
                <Typography variant="title">{t('common:attributeAggregation')}</Typography>
              </Box>

              <Box className="matrix-attribute">
                <Box className="matrix-manual-flex">
                  <Box className="matrix-manual-input">
                    <InputLabel>{t('common:attribute')}</InputLabel>
                    <Select
                      size="small"
                      displayEmpty
                      fullWidth
                      disabled={target === ''}
                      value={selectedKey}
                      onChange={(e) => {
                        setSelectedKey(e.target.value)
                        setKey(e.target.value?.label)
                        if (e.target.value?.default) setValue(e.target.value?.default)
                        setComparator('')
                      }}
                    >
                      <MenuItem disabled value="">
                        <Typography variant="subtitle3">{`${t('common:select')} ${t('common:attribute')}`}</Typography>
                      </MenuItem>
                      {target === 'KYB' &&
                        kybKeys?.map((item) => (
                          <MenuItem key={item.key} value={item}>
                            {item.key}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>

                  <Box className="matrix-manual-input">
                    <Box className="required">
                      <InputLabel>{t('common:condition')}</InputLabel>
                      <Typography variant="subtitle3">({t('common:required')})</Typography>
                    </Box>
                    <Select
                      size="small"
                      displayEmpty
                      fullWidth
                      disabled={target === ''}
                      value={comparator}
                      onChange={(e) => setComparator(e.target.value)}
                    >
                      <MenuItem disabled value="">
                        <Typography variant="subtitle3">{`${t('common:select')} ${t('common:condition')}`}</Typography>
                      </MenuItem>
                      {selectedKey?.comparators?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box className="matrix-manual-input">
                    <Box className="required">
                      <InputLabel>{t('common:value')}</InputLabel>
                      <Typography variant="subtitle3">({t('common:required')})</Typography>
                      {selectedKey?.type === 'list' && (
                        <RenderTooltip title={'To enter multiple values write and press enter.'} />
                      )}
                    </Box>
                    {selectedKey?.type !== 'list' && (
                      <TextField
                        fullWidth
                        disabled={selectedKey?.default ?? false}
                        value={value}
                        type={selectedKey?.type || 'string'}
                        onChange={(e) => setValue(e.target.value)}
                        required
                        placeholder={`${t('common:insert')} ${t('common:value')}`}
                      />
                    )}
                    {selectedKey?.type === 'list' && (
                      <Autocomplete
                        size="small"
                        value={valueList}
                        onChange={(event, newValue) => {
                          setValueList(newValue)
                        }}
                        multiple
                        options={[]}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" color="primary" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder={`${t('common:insert')} ${t('common:value')}`} />
                        )}
                      />
                    )}
                  </Box>

                  <Box className="matrix-manual-input">
                    <Box className="required">
                      <InputLabel>{t('common:score')}</InputLabel>
                      <Typography variant="subtitle3">({t('common:required')})</Typography>
                    </Box>
                    <TextField
                      fullWidth
                      type="number"
                      value={riskScore}
                      onChange={(e) => setRiskScore(e.target.value)}
                      required
                      placeholder={`${t('common:insert')} ${t('common:score')}`}
                    />
                  </Box>
                </Box>

                <Box className="matrix-add-button">
                  <Button
                    disabled={
                      !selectedKey ||
                      !riskScore ||
                      (selectedKey?.type === 'list' && !valueList.length) ||
                      (selectedKey?.type !== 'list' && !value) ||
                      !comparator
                    }
                    endIcon={<AddCircleOutlineIcon />}
                    variant="outlinedGrey"
                    onClick={() => handleAddItem()}
                  >
                    {t('common:add')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper
          elevation={0}
          variant="rootOutlined"
          className="matrix-manual-results-wrapper"
          sx={{ padding: '1.5rem' }}
        >
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Typography variant="title">{t('common:preview')}</Typography>
          </Box>
          {keys && Array.isArray(keys) && keys?.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>{t('common:attribute')}</TableCell>
                    <TableCell>{t('common:condition')}</TableCell>
                    <TableCell>{t('common:value')}</TableCell>
                    <TableCell>{t('common:score')}</TableCell>
                    {!id && <TableCell>{t('common:action')}</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys &&
                    keys?.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <TableRow>
                          <TableCell>
                            {kybKeys.filter((listItem) => listItem.label === item.key)
                              ? kybKeys.filter((listItem) => listItem.label === item.key)[0]?.key
                              : '--'}
                          </TableCell>
                          <TableCell>{item.comparator}</TableCell>
                          <TableCell
                            style={{
                              maxWidth: 100,
                              overflowWrap: 'break-word',
                            }}
                          >
                            {Array.isArray(item.value) ? JSON.stringify(item.value) : item.value}
                          </TableCell>
                          <TableCell>{item.riskScore}</TableCell>
                          {!id && (
                            <TableCell>
                              <Button
                                variant="outlinedError"
                                onClick={() => {
                                  handleDeleteItem(idx)
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Paper elevation={0} className="matrix-manual-legend">
              <Typography variant="legend" textAlign="center">
                {t('messages:manualMatrixPreview')}
              </Typography>
            </Paper>
          )}
        </Paper>
      </Box>

      <Box className="matrix-filter-buttons">
        <Button onClick={() => navigate('/admin/riskMatrix')} size="small" variant="outlinedGrey">
          {t('common:cancel')}
        </Button>

        {!id && (
          <Button variant="contained" disabled={name === ''} onClick={() => createMatrix()}>
            {t('common:save')}
          </Button>
        )}

        {id && (
          <Button variant="contained" disabled={name === ''} onClick={() => updateMatrix()}>
            {t('common:update')}
          </Button>
        )}
      </Box>

      <Modal open={askForAnother} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:action')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setAskForAnother(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          {isLoading ? (
            <ProcessingSpinner message={t('common:processing')} />
          ) : (
            <Box className="modal-box">
              <Typography variant="subtitle">{t('messages:createAnotherRiskMatrix')}</Typography>
              <Box className="modal-filter-buttons">
                <Button variant="outlined" onClick={() => navigate('/admin/riskMatrix')}>
                  {t('common:no')}
                </Button>
                <Button variant="contained" onClick={() => handleClose()}>
                  {t('common:yes')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  )
}

export default RiskMatrixCreation
