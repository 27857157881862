import { Box, Button, Modal, Tooltip, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useDeleteSubjectKybMutation } from '../../../services/guenoApi'

const DeleteRelationModal = ({ kybId, subjectId, relationType, disabled = false }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [deleteSubjectKyb] = useDeleteSubjectKybMutation()

  const handleDeleteClick = useCallback(() => {
    setSelectedSubject({ kybId, subjectId, relationType })
    setShowDeleteModal(true)
  }, [kybId, subjectId, relationType])

  const handleConfirmDelete = async () => {
    if (selectedSubject) {
      try {
        await deleteSubjectKyb(selectedSubject).unwrap()
        setShowDeleteModal(false)
      } catch (error) {
        console.error('Error al eliminar el sujeto:', error)
      }
    }
  }

  return (
    <>
      <Tooltip title={disabled ? t('common:kyb.actions.deleteLastBenecifiary') : ''} disabled={false}>
        <span>
          <Button
            onClick={() => handleDeleteClick()}
            size="small"
            sx={{
              fontSize: 10,
            }}
            color="error"
            variant="outlined"
            disabled={disabled}
          >
            <DeleteOutlineIcon />
          </Button>
        </span>
      </Tooltip>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(!showDeleteModal)}>
        <Box className="modal" gap="1.5rem">
          <Typography variant="title">
            {t(`common:REMOVE_SUBJECT_KYB_MODAL_TITLE_${selectedSubject?.relationType}`)}
          </Typography>
          <Box display="flex" justifyContent="space-evenly" mt="1.5rem">
            <Button variant="outlined" color="secondary" onClick={() => setShowDeleteModal(!showDeleteModal)}>
              {t('common:cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirmDelete}>
              {t('common:remove')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DeleteRelationModal
