import { Box, Button, Typography } from '@mui/material'
import DataTableSubjectsList from '../../../components/common/datatable/DataTableSubjectsList'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import AddShareholderModal from './AddShareholderModal'
import { useParams } from 'react-router-dom'
import AddRelatedPersonModal from './AddRelatedPersonModal'
import { useSelector } from 'react-redux'
import { getCountrySegment } from '../kyb.hook-helpers'
import { useFindSubjectsByKybQuery, useRunKybMutation } from '../../../services/guenoApi'
import toaster from '../../../toaster'

const TabComponent = ({
  entityType,
  subjectType,
  lastBeneficiaries,
  selectedTab,
  setSelectedTab,
  handleFetch,
  setErrorAssign,
  documentNumber = '',
  updateDetail = () => {},
}) => {
  const { accessToken } = useSelector((state) => state.login)
  const { id, country } = useParams()
  const currentCountry = country.toUpperCase()
  const countrySegment = getCountrySegment(currentCountry)
  const { t } = useTranslation(['common', 'messages'])
  const [modalRelatedPersonsOpen, setModalRelatedPersonsOpen] = useState(false)
  const [modalAddShareholderOpen, setModalShareholderOpen] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isFetchingModal, setIsFetchingModal] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [sortField, setSortField] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState(-1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [isRunningKyb, setIsRunningKyb] = useState('')
  const [runKyb] = useRunKybMutation()

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
  } = useFindSubjectsByKybQuery({
    id,
    selectedTab: selectedTab,
    entityType,
    page,
    limit: rowsPerPage,
    sortField,
    sortDirection,
    countrySegment,
  })

  const itemsToRender = useMemo(() => {
    if (Array.isArray(response?.data?.items)) {
      return response.data.items.map((item) => {
        let relationship = item.relationship
        if (Array.isArray(item.relationships)) {
          relationship = item.relationships.find((r) => r.documentNumber === documentNumber)
        }
        return {
          ...item,
          relationship,
        }
      })
    }

    return []
  }, [response?.data?.items, documentNumber])

  const hideStatusResponseFetch = () => {
    setTimeout(() => {
      setIsFetchingModal(false)
    }, 4000)
  }

  const addShareholderModal = (e, documentNumber, entity, roleType, relationship) => {
    e.preventDefault()
    setIsFetchingModal(true)
    setIsProcessing(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        documentNumber,
        entity,
        forEntityType: entityType,
        relationType: 'shareHolder',
        roleType,
        relationship,
      }

      const url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/subject`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          setIsProcessing(false)
          refetch()
          toaster.success(t('messages:creationSuccess'))
          setErrorAssign(false)
          hideStatusResponseFetch()
        })
        .catch((error) => {
          console.error('[CREATE SHAREHOLDER ERROR]', error.message)
          setIsProcessing(false)
          toaster.error(t('messages:editShareholderError'))
          setFetchError(true)
          setErrorAssign(true)
          hideStatusResponseFetch()
        })
        .finally(() => {
          setModalShareholderOpen(false)
        })
    } else {
      setIsProcessing(false)
      toaster.success(t('messages:creationSuccess'))
      setErrorAssign(false)
      hideStatusResponseFetch()
      setModalShareholderOpen(false)
    }
  }

  const addRelatedPersonModal = (e, documentNumber, entity, roleType, relationship) => {
    e.preventDefault()
    setFetchMessage(t('messages:processing'))
    setIsProcessing(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        documentNumber,
        forEntityType: entityType,
        entity,
        relationType: 'relatedPerson',
        roleType,
        relationship,
      }

      const url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/subject`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          refetch()
          setFetchMessage(t('messages:creationSuccess'))
          setErrorAssign(false)
        })
        .catch((error) => {
          console.error('[UPDATE CREATE RELATED PERSON ERROR]', error)
          setFetchMessage(t('messages:editShareholderError'))
          setFetchError(true)
          setErrorAssign(true)
        })
        .finally(() => {
          setModalRelatedPersonsOpen(false)
        })
    } else {
      setFetchMessage(t('messages:creationSuccess'))
      setErrorAssign(false)
      setModalRelatedPersonsOpen(false)
    }
  }

  const handleRunKyb = async (e, documentNumber, subjectId) => {
    e.preventDefault()
    setIsRunningKyb(documentNumber)

    if (!process.env.REACT_APP_IS_DEMO) {
      setIsRunningKyb(documentNumber)
      try {
        const res = await runKyb({ documentNumber, subjectId, countrySegment }).unwrap()
        if (res.success && res.data) {
          handleFetch(false, t('messages:runKybSuccess'))
          refetch()
        } else {
          throw new Error(
            res.data?.responseBody?.message
              ? res.data?.responseBody?.message
              : res.data?.info
              ? res.data?.info
              : res.message
              ? res.message
              : 'Unexpected',
          )
        }
      } catch (error) {
        handleFetch(true, t('messages:documentNotFound'))
      } finally {
        setIsRunningKyb('')
      }
    } else {
      setIsRunningKyb('')
      handleFetch(false, t('messages:runKybSuccess'))
    }
  }

  return (
    <Box>
      {subjectType === 'relatedPerson' && (
        <Box className="card-top" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className="card-titles" sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="title">{t('common:listOfRelatedPersons')}</Typography>
            <Typography variant="subtitle3">{t('messages:viewRelatedPersons')}</Typography>
          </Box>
          <Button onClick={() => setModalRelatedPersonsOpen(true)} size="small" variant="contained" color="secondary">
            {t('common:addRelatedPerson')}
          </Button>
        </Box>
      )}
      {subjectType === 'relatedCompany' && (
        <Box className="card-top" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className="card-titles" sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="title">{t('common:listOfRelatedCompanies')}</Typography>
            <Typography variant="subtitle3">{t('messages:viewRelatedCompanies')}</Typography>
          </Box>
        </Box>
      )}
      {subjectType === 'shareHolder' && (
        <Box className="card-top" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className="card-titles" sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="title">{t('common:listOfShareholders')}</Typography>
            <Typography variant="subtitle3">{t('messages:viewOwnership')}</Typography>
          </Box>
          <Button onClick={() => setModalShareholderOpen(true)} size="small" variant="contained" color="secondary">
            {t('common:addShareholder')}
          </Button>
        </Box>
      )}

      <DataTableSubjectsList
        rows={itemsToRender}
        pagination={{
          page,
          totalElements: response?.data?.pagination?.totalElements || 0,
          rowsPerPage,
          sortField,
          sortDirection,
        }}
        kybId={id}
        entityType={entityType}
        subjectType={subjectType}
        currentCountry={currentCountry}
        isRunningKyb={isRunningKyb}
        lastBeneficiaries={lastBeneficiaries}
        setSelectedTab={setSelectedTab}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setSortField={setSortField}
        setSortDirection={setSortDirection}
        handleRunKyb={handleRunKyb}
        loading={isLoading || isFetching}
        updateDetail={updateDetail}
      />
      {subjectType === 'shareHolder' && (
        <AddShareholderModal
          isOpen={modalAddShareholderOpen}
          onChangeIsOpen={setModalShareholderOpen}
          onSubmit={addShareholderModal}
          statusAddSubject={{
            fetchMessage,
            fetchError,
            isProcessing,
            isFetchingModal,
          }}
        />
      )}
      {subjectType === 'relatedPerson' && (
        <AddRelatedPersonModal
          isOpen={modalRelatedPersonsOpen}
          onChangeIsOpen={setModalRelatedPersonsOpen}
          onSubmit={addRelatedPersonModal}
          statusAddSubject={{
            fetchMessage,
            fetchError,
            isProcessing,
          }}
        />
      )}
    </Box>
  )
}

export default TabComponent
