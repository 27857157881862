import { createSlice } from '@reduxjs/toolkit'
import { searchTypes } from '../../pages/kyb/constants'

export const kycSlice = createSlice({
  name: 'kyc',
  initialState: {
    kycDetail: {},
    kycRelatedCompany: {},
    toAssign: [],
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      status: 'ALL',
      nationality: '',
      showMyAssignments: false,
      searchType: searchTypes[0],
      searchText: '',
    },
  },
  reducers: {
    updateKycDetail: (state, action) => {
      state.kycDetail = action.payload
    },
    updateKycShareholder: (state, action) => {
      state.kycRelatedCompany = action.payload
    },
    updateKycToAssign: (state, action) => {
      state.toAssign = action.payload
    },
    updateKycFilters: (state, action) => {
      state.filters = action.payload
    },
    addKycFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    }
  },
})

export const {
  updateKycDetail, updateKycFilters, updateKycToAssign, updateKycShareholder, addKycFilter,
} = kycSlice.actions;

export default kycSlice.reducer;
