/* eslint-disable no-self-assign */
import { useNavigate, useParams } from 'react-router-dom'
import './kyb.scss'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Fab,
  Link,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import { Status } from '../../components/utilities/Status'
import { KybChip } from '../../components/utilities/KybChip'
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/common/Accordion/Accordion'
import { useKybDetail } from '../pages-hooks/useKyb'
import { updateKybDetail, updateKybShareholder } from '../../features/kyb/kybSlice'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import KybToPdf from './KybToPdf'
import DatatableAssignUsers from '../../components/common/datatable/DatatableAssignedUsers'
import { useTranslation } from 'react-i18next'
import { RiskLevelSlider } from '../../components/utilities/RiskLevelSlider'
import { StatusIndicator } from '../../components/utilities/StatusIndicator'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { FileUploader } from 'react-drag-drop-files'
import { updateKycDetail } from '../../features/kyc/kycSlice'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'
import CustomNoRowsOverlay from '../../components/common/datagrid/CustomNoRowsOverlay'
import { selectAvailableCountries, setIsLoading } from '../../features/kyb/kybCountrySlice'
import { addAdditionalParams, getCountrySegment, removeLastWord } from './kyb.hook-helpers'
import MapComponent from '../../components/common/googleMaps/GoogleMapsComponent'
import KybPreviousChecks from './components/KybPreviousChecks'
import KybCheckDetail from './components/KybCheckDetail'
import TabComponent from './components/TabComponent'
import KybClusterModal from './components/KybClusterModal'
import { useFindUserMasterQuery, useRestartKybMutation, useUpdateKybMutation } from '../../services/guenoApi'
import KybChecks from './components/KybChecks.json'
import KybChecksDefaultOrder from './components/KybChecksDefaultOrder.json'
import KybTitle from './components/KybTitle'
import { colorsByStatus, iconByCountry } from './constants'
import KybEditFieldModal from './components/KybEditFieldModal'
import toaster from '../../toaster'
import KybModal from './components/KybModal'
import KybEditRiskLevelModal from './components/KybEditRiskLevelModal'
import KybEditCheckStatusModal from './components/KybEditCheckStatusModal'
import KybEditStatusModal from './components/KybEditStatusModal'
import KybNewCommentModal from './components/KybNewCommentModal'
import KybResetModal from './components/KybResetModal'

const KybDetail = ({ isShareholder, isPerson }) => {
  const { id, country } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const currentCountry = country.toUpperCase()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const languageState = useSelector((state) => state.language.lang)
  const { emailClient, email, accessToken, roles } = useSelector((state) => state.login)
  const { theme } = useSelector((state) => state.theme)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [modalNewCommentOpen, setModalNewCommentOpen] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [openModalCheckStatus, setOpenModalCheckStatus] = useState(false)
  const [modalOpen5, setModalOpen5] = useState(false)
  const [modalEditCluster, setModalEditCluster] = useState(false)
  const [openModalEditField, setOpenModalEditField] = useState(false)
  const [modalEditField, setModalEditField] = useState({ label: '', value: '' })
  const [openModalKybReset, setOpenModalKybReset] = useState(false)
  const [checkId, setCheckId] = useState('')
  const [checkStatus, setCheckStatus] = useState('')
  const [checkIndex, setCheckIndex] = useState('')
  const [checkIndexMain, setCheckIndexMain] = useState('')
  const [toAssign, setToAssign] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [isEditingAssign, setIsEditingAssign] = useState(false)
  const [errorAssign, setErrorAssign] = useState(false)
  const [isProcessingFile, setIsProcessingFile] = useState(false)
  const [selectedTab, setSelectedTab] = useState('checks')
  const [coordinates, setCoordinates] = useState(null)

  const { data: userMasters } = useFindUserMasterQuery({ emailClient })
  const [updateKyb] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })
  const [restartKyb, { isLoading: restartIsLoading }] = useRestartKybMutation()
  const countrySegment = useMemo(() => getCountrySegment(currentCountry), [currentCountry])

  const handleRestart = async () => {
    try {
      const response = await restartKyb({ countrySegment, kybId: id })

      if (response) updateDetail()
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateKyb = async (field, value) => {
    try {
      const { data } = await updateKyb({
        kybId: id,
        countrySegment,
        emailClient,
        body: {
          [field]: value,
        },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        updateDetail()
      } else {
        toaster.error(data?.message ? data?.message : t('messages:updateError'))
      }
    } catch (error) {
      toaster.error(error.message ? error.message : t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  const entityType = isShareholder || isPerson ? 'PERSON' : 'BUSINESS'

  const checks = useMemo(() => {
    if (!currentCountry || !entityType) return []

    let sortedList = KybChecksDefaultOrder[currentCountry][entityType]

    if (userMasters?.kybChecks?.[currentCountry]?.[entityType])
      sortedList = userMasters.kybChecks?.[currentCountry]?.[entityType]

    return KybChecks[currentCountry][entityType].sort(
      (a, b) => sortedList.indexOf(a.title) - sortedList.indexOf(b.title),
    )
  }, [userMasters?.kybChecks, currentCountry, entityType])

  const [expanded, setExpanded] = useState({})
  const [expandedMain, setExpandedMain] = useState([false, false, false, false, false, false])

  useKybDetail(id, isShareholder, isPerson)

  const CustomTabList = styled(TabList)({
    borderBottom: '1px solid #1C282026',
    '& ::-webkit-scrollbar-thumb': {
      background: theme === 'light' ? '#c9d2cc' : '#57635b',
    },
  })

  const kybBase = useSelector((state) => (isShareholder ? state.kyb.kybDetail : isPerson ? state.kyc : state.kyb))
  const kyb = useSelector((state) =>
    isShareholder ? state.kyb.kybShareholder : isPerson ? state.kyc.kycDetail : state.kyb.kybDetail,
  )

  const isPrivate = useMemo(() => (typeof kyb.isPrivate === 'boolean' ? kyb.isPrivate : false), [kyb.isPrivate])

  const identityVerificationId = useMemo(() => kyb.identityVerificationId ?? '', [kyb.identityVerificationId])

  const currentDocumentNumber = useMemo(() => {
    if (isPerson || isShareholder) {
      return kyb?.documentNumber
    }
    return kyb?.companyDetails?.documentNumber
  }, [kyb, isPerson, isShareholder])

  const nationalityIconUrl = useMemo(() => {
    const nationality = (kyb?.companyDetails?.country || kyb?.nationality || '').toLowerCase()
    if (!nationality) return null

    return `https://flagcdn.com/16x12/${iconByCountry[nationality] || nationality}.png`
  }, [kyb?.companyDetails?.country, kyb?.nationality])

  // usually with state.kybCountry we would lower down string 'countrySegment',
  // but since it is not used in kybDetail (for scope limitations on link sharing) we define it below these
  // const { isLoading } = useSelector((state) => state.kybCountry)
  const { isLoading: availableCountriesLoading } = useSelector((state) => selectAvailableCountries(state, emailClient))

  const timelineList = useMemo(() => {
    if (isShareholder || isPerson) {
      const timeline = kyb?.timeline || kybBase?.timeline || []
      return [...timeline].reverse()
    } else {
      const timeline = kyb?.timeline || []
      return [...timeline]?.reverse()
    }
  }, [isPerson, isShareholder, kybBase?.timeline, kyb?.timeline])

  const getStyles = (type) => {
    let color
    let backgroundColor

    switch (type.toLowerCase()) {
      case 'creation':
        backgroundColor = '#4184c3'
        color = '#fafafa'
        break
      case 'complete':
        backgroundColor = '#00B85F'
        color = '#FBFFFC'
        break
      default:
        backgroundColor = '#FF8A00'
        color = '#FBFFFC'
        break
    }

    return { color, backgroundColor }
  }
  const closeAccordions = (activePosition) => {
    const newState = {}

    Object.keys(expanded).forEach((key) => {
      newState[key] = false
    })

    newState[activePosition] = true

    setExpanded(newState)
  }
  const handleChangeExpanded = (indexMain, index) => {
    const key = `${indexMain}-${index}`
    const newState = { ...expanded, [key]: !expanded[key] }
    setExpanded(newState)
  }
  const handleChangeExpandedMain = (indexMain) => {
    const newState = { ...expandedMain }
    newState[indexMain] = !newState[indexMain]
    setExpandedMain(newState)
  }
  const handleFetch = () => {
    setModalOpen5(false)
    setOpenModalCheckStatus(false)
    setModalOpen2(false)
    setModalNewCommentOpen(false)
    setModalEditCluster(false)
    setOpenModalEditField(false)
  }

  const handleOpenModalEdit = useCallback(() => {
    setModalEditCluster(true)
  }, [setModalEditCluster])

  const handleOpenModalEditField = useCallback(
    (label, value) => {
      setModalEditField({ label, value: value ?? '' })
      setOpenModalEditField(true)
    },
    [setOpenModalEditField],
  )

  const updateDetail = useCallback(() => {
    let baseUrl
    if (isShareholder) {
      baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else if (isPerson) {
      baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else {
      baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/kybDetails.json' : baseUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (isShareholder) {
          dispatch(updateKybShareholder(res.data))
        } else if (isPerson) {
          dispatch(updateKycDetail(res.data))
        } else {
          dispatch(updateKybDetail(res.data))
        }
        dispatch(setIsLoading(false))
      })
      .catch((error) => {
        console.error('[HOOK: useKybDetail] --> ', error)
        if (isShareholder) {
          dispatch(updateKybShareholder({ statusCode: 404 }))
        } else if (isPerson) {
          dispatch(updateKycDetail({ statusCode: 404 }))
        } else {
          dispatch(updateKybDetail({ statusCode: 404 }))
        }
        dispatch(setIsLoading(false))
      })
  }, [accessToken, countrySegment, dispatch, id, isPerson, isShareholder])

  // STATUS KYB
  const handleUpdate = (data) => {
    let urlUser

    if (isShareholder) {
      urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else if (isPerson) {
      urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else {
      urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
    }

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'))
          } else {
            toaster.error(typeof res.data === 'string' ? res.data : res.message)
          }
          handleFetch()
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE STATUS ERROR] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'))
          handleFetch()
        })
    } else {
      toaster.success(t('messages:updateSuccess'))
    }
  }

  const handleIsPrivate = () => {
    const url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/private`

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ isPrivate: !isPrivate }),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:updateSuccess'))
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE STATUS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateError'))
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
    }
  }

  // RISK LEVEL

  const handleUpdateRiskLevel = (level) => {
    if (!process.env.REACT_APP_IS_DEMO) {
      const urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/riskLevel/${level}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch()
          toaster.success(t('messages:updateSuccess'))
          updateDetail()
        })
        .catch((error) => {
          toaster.error(error.message ? error.message : t('messages:updateError'))
          handleFetch()
        })
    } else {
      toaster.success(t('messages:updateSuccess'))
      handleFetch()
      updateDetail()
    }
  }

  const handleCheck = (e, type, index, indexMain) => {
    e.preventDefault()
    closeAccordions(`${indexMain}-${index}`)

    const requiresNameAndLast = type === 'procesos-judiciales-co' || type === 'fiscalia-co' || type === 'secop-co'

    const requiresOnlyLastName = type === 'inpec-co' || type === 'experian-hcpn-co'

    const splitName = kyb?.name ? kyb.name.split(' ') : []
    const lastName = splitName.length ? splitName[splitName.length - 1] : undefined
    const firstName = splitName.length ? splitName[0] : undefined

    let data = {
      id: id,
      documentNumber: isShareholder || isPerson ? kyb?.documentNumber : kyb?.companyDetails?.documentNumber,
      entity: isShareholder || isPerson ? 'PERSON' : 'BUSINESS',
      type,
      ...(requiresNameAndLast && {
        name: firstName ?? removeLastWord(kyb?.companyDetails?.name).modifiedString,
      }),
      ...((requiresOnlyLastName || requiresNameAndLast) && {
        last_name: lastName ?? removeLastWord(kyb?.companyDetails?.name).lastWord,
      }),
      date_expedition:
        type === 'registraduria-co' || type === 'inhabilidades-co' || type === 'rmnc-co'
          ? kyb?.companyDetails?.dateOfRegistration ?? '1994-05-03'
          : undefined,
    }

    data = addAdditionalParams(type, data)
    const urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}check`
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(urlUser, options)
        .then(async (res) => {
          const jsonRes = await res.json()
          return jsonRes
        })
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:creationSuccess'))
          updateDetail()
        })
        .catch((error) => {
          console.error('[CREATE CHECK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:creationError'))
        })
    } else {
      handleFetch(false, t('messages:creationSuccess'))
    }
  }

  const handleCheckUpdate = (checkId, data, index, indexMain, type) => {
    closeAccordions(`${indexMain}-${index}`)

    data = addAdditionalParams(type, data)

    const urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}check/${checkId}`
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'))
            updateDetail()
          } else {
            toaster.error(typeof res.data === 'string' ? res.data : res.message)
          }
          handleFetch()
          setCheckId('')
          setCheckStatus('')
          setCheckIndex('')
          setCheckIndexMain('')
        })
        .catch((error) => {
          console.error('[UPDATE CHECK STATUS ERROR] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'))
          handleFetch()
        })
    } else {
      handleFetch()
      toaster.success(t('messages:updateSuccess'))
      setCheckId('')
      setCheckStatus('')
      setCheckIndex('')
      setCheckIndexMain('')
    }
  }

  // COMMENTS
  const postNewComment = (newComment) => {
    let url

    if (isPerson || isShareholder) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}/comments`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/comments`
    }

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'POST',
      body: JSON.stringify({
        emailClient: emailClient,
        email: email,
        description: newComment,
      }),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then(() => {
          handleFetch()
          toaster.success(t('messages:createCommentSuccess'))
          updateDetail()
        })
    } else {
      toaster.success(t('messages:createCommentSuccess'))
    }
  }

  // FILES
  const uploadFile = (file) => {
    const formData = new FormData()
    formData.append('file', file)

    setIsProcessingFile(true)

    let url

    if (isPerson) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}/file`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/file`
    }

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            handleFetch(false, t('messages:uploadFileSuccess'))
            dispatch(setIsLoading(false))
            updateDetail()
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
          setIsProcessingFile(false)
        })
        .catch((error) => {
          console.error('[UPLOAD KYB FILE ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:uploadFileError'))
          setIsProcessingFile(false)
        })
    } else {
      handleFetch(false, t('messages:uploadFileSuccess'))
      dispatch(setIsLoading(false))
      setIsProcessingFile(false)
    }
  }

  const deleteFile = (e, file) => {
    e.preventDefault()
    setIsProcessingFile(true)
    const splittedName = file.split('/kyb/')[1].split('?')
    const key = splittedName[splittedName.length - 2]

    let url

    if (isPerson) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}/file/${key}`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/file/${key}`
    }

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            handleFetch(false, t('messages:deletionSuccess'))
            dispatch(setIsLoading(false))
            setIsProcessingFile(false)
            updateDetail()
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
          setIsProcessingFile(false)
        })
        .catch((error) => {
          console.error('[DELETE KYB FILE ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:deletionError'))
          setIsProcessingFile(false)
        })
    } else {
      handleFetch(false, t('messages:deletionSuccess'))
      dispatch(setIsLoading(false))
    }
  }

  // ASSIGNS
  const updateAssigns = (assignedUsers) => {
    let url

    if (isPerson) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
    }

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ assignedUsers }),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          updateDetail()
          handleFetch(false, t('messages:updateSuccess'))
          setErrorAssign(false)
        })
        .catch((error) => {
          console.error('[UPDATE CASE ASSIGNED USERS ERROR]', error)
          handleFetch(true, t('messages:editAssigUserError'))
          setErrorAssign(true)
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
      setErrorAssign(false)
    }
  }

  useEffect(() => {
    if (kyb?.assignedUsers) {
      setAssignedUsers(kyb?.assignedUsers)
    } else {
      setAssignedUsers([])
    }
  }, [kyb])

  const finalAddress = useMemo(() => {
    if (Array.isArray(kyb?.companyDetails?.addresses) && kyb.companyDetails.addresses.length) {
      const aux = kyb?.companyDetails?.addresses[0]

      return typeof aux === 'object'
        ? `${aux.street}, ${aux.number}, ${aux.city}, ${aux.state}, ${aux.postcode}, ${aux.country}`
        : aux
    }

    return ''
  }, [kyb?.companyDetails?.addresses])

  const finalPhones = useMemo(() => {
    if (Array.isArray(kyb?.companyDetails?.telefones) && kyb.companyDetails.telefones.length > 0) {
      return kyb?.companyDetails?.telefones
        .filter((phone) => !!phone && (phone?.ddd || phone?.numero))
        .map((phone) => `${phone.ddd || ''} ${phone.numero || ''}`)
        .map((text) => text.trim())
        .join('; ')
    }

    return ''
  }, [kyb?.companyDetails?.telefones])

  useEffect(() => {
    if (kybBase?.toAssign?.length) {
      setToAssign(kybBase.toAssign)
    }
  }, [kybBase])

  useEffect(() => {
    dispatch(setIsLoading(true))
  }, [dispatch, languageState])

  useEffect(() => {
    updateDetail()
  }, [id, country, updateDetail])

  if (availableCountriesLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={`${restartIsLoading && 'spinner-transition'} filter`}>
      {/* Main KYB Detail Component */}
      <Box className="kyb-detail">
        {/* Header Section */}
        <Box className="kyb-detail-head">
          <Paper elevation={0} sx={{ mr: '1rem', padding: '1rem' }}>
            <Box className="kyb-info">
              <Box className="kyb-info-section-head">
                <Box className="kyb-info-section-1">
                  {/* Company/Person Name */}
                  <Typography variant="h4">
                    {kyb?.companyDetails?.name ? kyb.companyDetails.name : kyb?.name ? kyb?.name : '---'}
                  </Typography>
                  {/* Account Type Chip */}
                  {isShareholder || isPerson ? (
                    <Chip
                      label={t('common:personAccount')}
                      icon={<PersonOutlineOutlinedIcon sx={{ fill: '#fbfffc' }} />}
                      sx={{ backgroundColor: '#00bfe9' }}
                    />
                  ) : (
                    <Chip
                      label={t('common:businessAccount')}
                      icon={<WorkOutlineOutlinedIcon sx={{ fill: '#fbfffc' }} />}
                      sx={{ backgroundColor: '#6ede92' }}
                    />
                  )}
                </Box>
              </Box>
              {!isShareholder && !isPerson ? (
                <Box className="kyb-info-section-2">
                  {/* Document Number */}
                  <KybTitle
                    name={t('common:documentNumber')}
                    value={
                      kyb?.companyDetails?.documentNumber
                        ? kyb.companyDetails.documentNumber
                        : kyb?.documentNumber
                        ? kyb?.documentNumber
                        : '---'
                    }
                    copyable
                  />

                  {/* Fantasia */}
                  <KybTitle name={t('common:fantasyName')} value={kyb?.companyDetails?.fantasia ?? '---'} />

                  {/* Date of Registration */}
                  <KybTitle
                    name={t('common:dateOfRegistration')}
                    value={kyb?.companyDetails?.dateOfRegistration ?? '---'}
                  />

                  <Box className="kyb-info-section-4 kyb-info-section-span">
                    {/* Situation */}
                    <KybTitle name={t('common:situation')} value={kyb?.companyDetails?.situacao?.nome ?? '---'} />

                    {/* Social Capital */}
                    <KybTitle name={t('common:socialCapital')} value={kyb?.companyDetails?.capitalSocial ?? '---'} />

                    {/* Legal Nature */}
                    <KybTitle
                      name={t('common:legalNature')}
                      value={kyb?.companyDetails?.naturezaJuridica?.descricao ?? '---'}
                    />

                    {/* Size */}
                    <KybTitle name={t('common:size')} value={kyb?.companyDetails?.porte?.descricao ?? '---'} />
                  </Box>

                  {/* CNAE Main */}
                  <KybTitle
                    name={t('common:cnaeMain')}
                    value={[
                      `◎ ${kyb?.companyDetails?.cnae?.subClasse ?? '---'}: `,
                      kyb?.companyDetails?.cnae?.descricao ?? '---',
                    ]}
                  />

                  {/* CNAE Secondary */}
                  <KybTitle
                    name={t('common:cnaeSecondary')}
                    value={
                      kyb?.companyDetails?.cnae?.secundarias?.length > 0
                        ? kyb?.companyDetails?.cnae?.secundarias
                            ?.map((s) => `◎ ${s.subclasse}: ${s.descricao}`)
                            .join('\n')
                        : '---'
                    }
                  />

                  {/* Address */}
                  <KybTitle
                    name={t('common:address')}
                    value={
                      finalAddress !== ''
                        ? finalAddress !== ', -, -, -, -, BR'
                          ? finalAddress
                          : 'Address not found.'
                        : 'Address not found.'
                    }
                    icon={
                      coordinates ? (
                        <Box className="kyb-address-icon">
                          <Link
                            href={`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}&z=16`}
                            target="_blank"
                            variant="contained"
                            color="primary"
                          >
                            <Typography variant="number">{t('common:openMap')}</Typography>
                          </Link>
                        </Box>
                      ) : (
                        <></>
                      )
                    }
                  />

                  <Box className="kyb-info-section-4 kyb-info-section-span">
                    {/* Cluster */}
                    <KybTitle
                      name={t('common:cluster')}
                      value={kyb?.cluster ?? '---'}
                      onEditClick={handleOpenModalEdit}
                      editable
                    />

                    {/* site */}
                    <KybTitle
                      name={t('common:site')}
                      value={kyb?.site ?? '---'}
                      onEditClick={() => handleOpenModalEditField('site', kyb?.site)}
                      editable
                    />

                    {/* Simples Nacional */}
                    <KybTitle
                      name={t('common:simplesNacional')}
                      value={kyb?.companyDetails?.simplesNacional?.optante || '---'}
                    />

                    {/* Legal Representative */}
                    <KybTitle
                      name={t('common:legalRepresentative')}
                      value={kyb?.legalRepresentative ?? '---'}
                      onEditClick={() => handleOpenModalEditField('legalRepresentative', kyb?.legalRepresentative)}
                      editable
                    />
                  </Box>

                  {/* Legal Representative Document Number */}
                  <KybTitle
                    name={t('common:legalRepresentativeDocumentNumber')}
                    value={kyb?.legalRepresentativeDocumentNumber ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField(
                        'legalRepresentativeDocumentNumber',
                        kyb?.legalRepresentativeDocumentNumber,
                      )
                    }
                    editable
                  />

                  {/* Legal Representative Email */}
                  <KybTitle
                    name={t('common:legalRepresentativeEmail')}
                    value={kyb?.legalRepresentativeEmail ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField('legalRepresentativeEmail', kyb?.legalRepresentativeEmail)
                    }
                    editable
                  />

                  {/* Legal Representative Phone */}
                  <KybTitle
                    name={t('common:legalRepresentativePhone')}
                    value={kyb?.legalRepresentativePhone ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField('legalRepresentativePhone', kyb?.legalRepresentativePhone)
                    }
                    editable
                  />

                  <Box className="kyb-info-section-4 kyb-info-section-span">
                    {/* Phone */}
                    <KybTitle name={t('common:phones')} value={finalPhones || '---'} />

                    {/* Email */}
                    <KybTitle
                      name={t('common:email')}
                      value={
                        kyb?.companyDetails?.emails?.length
                          ? kyb.companyDetails.emails[0]
                          : kybBase?.companyDetails?.emails?.length
                          ? kybBase?.companyDetails?.emails[0]
                          : '---'
                      }
                    />

                    {/* Date of Creation */}
                    <KybTitle
                      name={t('common:creationDate')}
                      value={kyb?.createdAt ? buildTimeStringFromTimestamp(kyb.createdAt) : '---'}
                    />

                    {/* Date of last update */}
                    <KybTitle
                      name={t('common:updatedAt')}
                      value={kyb?.updatedAt ? buildTimeStringFromTimestamp(kyb.updatedAt) : '---'}
                    />
                  </Box>
                </Box>
              ) : (
                <Box className="kyb-info-section-2">
                  {/* Document Number */}
                  <KybTitle name={t('common:documentNumber')} value={kyb?.documentNumber ?? '---'} copyable />

                  {/* Date of Registration */}
                  <KybTitle
                    name={t('common:createdAt')}
                    value={kyb?.createdAt ? buildTimeStringFromTimestamp(kyb.createdAt) : '---'}
                  />

                  {/* Last Updated */}
                  <KybTitle
                    name={t('common:lastUpdated')}
                    value={kyb?.updatedAt ? buildTimeStringFromTimestamp(kyb.updatedAt) : '---'}
                  />

                  {/* Country */}
                  <KybTitle
                    name={t('common:country')}
                    value={
                      <>
                        {kyb?.nationality}
                        {'  '}
                        {!!nationalityIconUrl && <img alt="img" src={nationalityIconUrl} width="16" height="12" />}
                      </>
                    }
                  />
                </Box>
              )}
            </Box>
          </Paper>
          <Box className="details-sub">
            {entityType === 'BUSINESS' && roles.includes('MANAGER') && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                }}
              >
                <Switch
                  checked={isPrivate}
                  onChange={handleIsPrivate}
                  // disabled={!!isProcessing} TO-DO: update
                />
                <Typography variant="subtitle2">{t('common:private')}</Typography>
              </Box>
            )}
            <Button
              sx={{ width: '100%' }}
              size="small"
              onClick={() => setModalNewCommentOpen(true)}
              variant="contained"
            >
              {t('common:postComment')}
            </Button>
            {entityType === 'BUSINESS' && (
              <Button sx={{ width: '100%' }} size="small" onClick={() => setOpenModalKybReset(true)} variant="outlined">
                {t('common:reset')}
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)!important' }} />}
            >
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
            {entityType === 'PERSON' && (
              <Button
                sx={{ width: '100%' }}
                size="small"
                onClick={() => {
                  if (identityVerificationId === '') {
                    navigate(`/onboardings/manual?kyc=${id}`)
                  } else {
                    navigate(`/onboardings/${identityVerificationId}`)
                  }
                }}
                variant="outlined"
              >
                {identityVerificationId
                  ? t('common:SEE_KYC_IDENTITY_VERIFICATION')
                  : t('common:MAKE_KYC_IDENTITY_VERIFICATION')}
              </Button>
            )}
            {!isShareholder && !isPerson && (
              <Paper elevation={0} sx={{ p: '1rem' }} className="risk-level">
                <Box className="risk-level-top">
                  <Typography variant="subtitle2">{t('common:riskLevel')}</Typography>
                  <Button size="small" onClick={() => setModalOpen5(true)} variant="outlined">
                    {t('common:edit')}
                  </Button>
                </Box>
                {kyb?.riskLevel ? <RiskLevelSlider type={kyb?.riskLevel} /> : <RiskLevelSlider type={'LOW'} />}
              </Paper>
            )}
            <Paper elevation={0} sx={{ p: '1rem' }} className="status-level">
              <Box className="status-level-top">
                <Typography variant="subtitle2">{t('common:status')}</Typography>
                <Button size="small" onClick={() => setModalOpen2(true)} variant="outlined">
                  {t('common:edit')}
                </Button>
              </Box>
              {kyb?.status ? (
                <StatusIndicator type={kyb?.status} color={colorsByStatus[kyb.status]} />
              ) : (
                <StatusIndicator type={'NOT_STARTED'} />
              )}
            </Paper>
            {!isShareholder && finalAddress !== '' && (
              <Box>
                <MapComponent fixedAddress={finalAddress} onCoordinatesFetched={setCoordinates} />
              </Box>
            )}
          </Box>
        </Box>
        <Box className="kyb-subdetail">
          <Box sx={{ paddingRight: '1rem' }}>
            <Paper className="kyb-detail-timeline" elevation={0} sx={{ padding: '1rem' }}>
              <Typography variant="h4">{t('common:timelineAndActivity')}</Typography>
              {Array.isArray(timelineList) && (
                <Box className="timeline-wrapper">
                  {timelineList.map((timeline) => {
                    const { color, backgroundColor } = getStyles(timeline.type)
                    return (
                      <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                        <Box className="timeline-box">
                          <Box title={t(`common:${timeline.type}`)}>
                            <KybChip
                              action={t(`common:${timeline.type}`)}
                              color={color}
                              backgroundColor={backgroundColor}
                            />
                          </Box>
                        </Box>
                        {buildTimeStringFromTimestamp(timeline.createdAt)}
                        <Box className="timeline-box">
                          <p>{t('common:status')}:</p>
                          <Typography variant="text2">
                            {t(
                              `common:${
                                timeline.status === 'SUCCESS'
                                  ? 'ACTION_FINISHED_WITHOUT_ERROR'
                                  : timeline.status === 'ERROR'
                                  ? 'ACTION_FINISHED_WITH_ERROR'
                                  : timeline.status
                              }`,
                            )}
                          </Typography>
                        </Box>
                        {[
                          'SHAREHOLDER_CREATION',
                          'RELATEDPERSON_DELETION',
                          'RELATEDPERSON_CREATION',
                          'SHAREHOLDER_DELETION',
                        ].includes(timeline.type) &&
                          timeline.detail && (
                            <Box className="timeline-box">
                              <p>{t('common:details')}:</p>
                              <Typography variant="text2">
                                {`${t('common:documentN')}: ${timeline.detail?.documentNumber}`}
                              </Typography>
                            </Box>
                          )}
                        {['RELATED_COMPANIES_ADDED', 'RELATED_COMPANIES_REMOVED'].includes(timeline.type) && (
                          <Box className="timeline-box">
                            <p>{t('common:details')}:</p>
                            <Typography variant="text2">{timeline.description}</Typography>
                          </Box>
                        )}
                        <Box className="timeline-box">
                          <p>{t('common:operationalAgent')}:</p>
                          <Typography variant="text2">{timeline.email}</Typography>
                        </Box>
                      </Paper>
                    )
                  })}
                </Box>
              )}
            </Paper>
          </Box>
          <Box className="kyb-subgrid">
            <TabContext value={selectedTab}>
              <CustomTabList
                TabIndicatorProps={{ style: { display: 'none' } }}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="scrollable"
              >
                <Tab
                  label={
                    <Chip
                      label={t('common:checks')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'checks' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  sx={{ maxWidth: isShareholder ? '50%' : '100%' }}
                  value="checks"
                ></Tab>
                {!isShareholder && (
                  <Tab
                    label={
                      <Chip
                        label={t('common:documents')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'documents' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="documents"
                  />
                )}
                {
                  <Tab
                    label={
                      <Chip
                        label={t('common:relatedPersons')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'relatedPersons' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="relatedPersons"
                  />
                }
                {(isShareholder || isPerson) && (
                  <Tab
                    label={
                      <Chip
                        label={t('common:relatedCompanies')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'relatedCompanies' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="relatedCompanies"
                  />
                )}
                {!isShareholder && !isPerson && (
                  <Tab
                    label={
                      <Chip
                        label={t('common:shareholders')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'shareHolders' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="shareHolders"
                  />
                )}
                {!isShareholder && (
                  <Tab
                    label={
                      <Chip
                        label={t('common:assignedUsers')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'assignedUsers' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="assignedUsers"
                  />
                )}
                <Tab
                  label={
                    <Chip
                      label={t('common:comments')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'comments' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="comments"
                />
              </CustomTabList>
              <Box>
                <TabPanel value="checks">
                  <Box className="kyb-checks-flex">
                    {checks?.map((mainChecks, indexMain) => (
                      <Accordion
                        key={indexMain}
                        expanded={expandedMain[indexMain]}
                        onChange={() => handleChangeExpandedMain(indexMain)}
                      >
                        <AccordionSummary expandIcon={null}>
                          <Box className="accordion-wrapper">
                            <Box className="accordion-status">
                              <Typography variant="title">{t(`common:${mainChecks?.title}`)}</Typography>
                              <Typography variant="subtitle3">{t(`messages:${mainChecks?.subtitle}`)}</Typography>
                            </Box>
                            <Button
                              variant="outlinedBlank"
                              onChange={(e) => {
                                e.stopPropagation()
                                return handleChangeExpandedMain(indexMain)
                              }}
                            >
                              {expandedMain[indexMain] ? (
                                <KeyboardDoubleArrowDownIcon />
                              ) : (
                                <KeyboardDoubleArrowUpIcon />
                              )}
                            </Button>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {mainChecks?.items?.map((check, index) => {
                            const key = `${indexMain}-${index}`
                            return (
                              <Accordion
                                key={key}
                                expanded={expanded[key] || false}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  handleChangeExpanded(indexMain, index)
                                }}
                                variant="medium"
                                slotProps={{ transition: { unmountOnExit: true } }}
                              >
                                <AccordionSummary expandIcon={null}>
                                  <Box className="accordion-wrapper">
                                    <Box className="accordion-status">
                                      <Typography variant="text2">{t(`common:${check.title}`)}</Typography>
                                      <Box>
                                        {kyb[check.name] ? (
                                          <Box className="accordion-status-icon">
                                            <Status
                                              type={kyb[check.name].status}
                                              noIcon={true}
                                              sizeBig={true}
                                              variant="colorSecondary"
                                            />
                                          </Box>
                                        ) : (
                                          <Box className="accordion-status-icon">
                                            <Status
                                              type={'NOT_STARTED'}
                                              noIcon={true}
                                              sizeBig={true}
                                              variant="colorSecondary"
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                    <Button
                                      variant="outlinedBlank"
                                      onChange={(e) => {
                                        e.stopPropagation()
                                        return handleChangeExpanded(index)
                                      }}
                                    >
                                      {expanded[index] ? (
                                        <KeyboardDoubleArrowDownIcon />
                                      ) : (
                                        <KeyboardDoubleArrowUpIcon />
                                      )}
                                    </Button>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box>
                                    {kyb[check.name] && !isProcessing ? (
                                      <KybCheckDetail name={check.name} value={kyb[check.name]} />
                                    ) : (
                                      <Box>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          color="secondary"
                                          // disabled={isFetching} TO-DO: update
                                          onClick={(e) => handleCheck(e, check.key, index, indexMain)}
                                        >
                                          {t('common:run')} {camelCaseFormatter(check.name)}
                                        </Button>
                                      </Box>
                                    )}
                                    <Box className="kyb-close">
                                      {kyb[check.name] && (
                                        <Box className="kyb-check-status">
                                          <KybPreviousChecks
                                            kybId={id}
                                            type={check.key}
                                            checks={checks}
                                            countrySegment={countrySegment}
                                          />
                                          <Button
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                              setOpenModalCheckStatus(!openModalCheckStatus)
                                              setCheckId(kyb[check.name]?._id)
                                              setCheckStatus(kyb[check.name]?.status)
                                              setCheckIndex(index)
                                              setCheckIndexMain(indexMain)
                                            }}
                                            variant="contained"
                                          >
                                            {t('common:changeStatus')}
                                          </Button>
                                          <Button
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            // disabled={isFetching} TO-DO: update
                                            onClick={(e) => handleCheck(e, check.key, index, indexMain)}
                                          >
                                            {t('common:run')} {camelCaseFormatter(check.name)} {t('common:again')}
                                          </Button>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )
                          })}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </TabPanel>
                {!isShareholder && (
                  <TabPanel value="documents">
                    <Paper elevation={0}>
                      <Box className="kyb-detail-documents">
                        <Box className="kyb-documents-top">
                          <Typography variant="title">{t('common:documents')}</Typography>
                          <Typography variant="subtitle3">{t('messages:updatePDFRelated')}</Typography>
                        </Box>
                        <Box className="kyb-files-drag">
                          <Typography variant="title2">{t('common:addAFile')}</Typography>
                          <FileUploader
                            handleChange={(file) => uploadFile(file)}
                            name="file"
                            label={t('common:fileUploaderLabel')}
                            types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'DOC', 'CSV']}
                          >
                            <Paper elevation={0} variant="rootDashedTransparent" className="draggable-main">
                              <Box className="draggable-icon"></Box>
                              <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                              <Box>
                                <Typography variant="subtitle4">{t('common:or')} </Typography>
                                <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                                  {t('common:browseMedia')}
                                </Typography>
                              </Box>
                            </Paper>
                          </FileUploader>
                          <Typography variant="subtitle4">
                            {t('common:supportedMedia')}: JPG, PNG, GIF, MP4, MPEG, WEBM, MP3, WAV, PDF.
                          </Typography>
                        </Box>
                        <Box className="kyb-file-list-wrapper">
                          <Typography variant="title"> {t('common:uploadedDocuments')}</Typography>
                          <Box className="kyb-file-list">
                            {isProcessingFile ? (
                              <ProcessingSpinner message={t('common:processing')} />
                            ) : (
                              <Box>
                                {Array.isArray(kyb?.files) && kyb.files.length > 0 && (
                                  <Box className="kyb-files">
                                    {kyb?.files[0] instanceof String &&
                                      kyb.files.map((file, index) => {
                                        const splittedName = file.split('/kyb/')[1].split('_')
                                        const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                                        return (
                                          <Paper elevation={0} variant="colorPrimary" className="kyb-file" key={index}>
                                            <Box className="kyb-file-description">
                                              <Box className="color-button">
                                                <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                                                  DOC
                                                </Typography>
                                              </Box>
                                              <Typography variant="text2" title={fileName}>
                                                {fileName}
                                              </Typography>
                                            </Box>
                                            <Box className="kyb-file-icons">
                                              <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => window.open(file, '_blank')}
                                              >
                                                {t('common:view')}
                                              </Button>
                                              <Button variant="outlinedBlank" onClick={(e) => deleteFile(e, file)}>
                                                <DeleteOutlineIcon />
                                              </Button>
                                            </Box>
                                          </Paper>
                                        )
                                      })}
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </TabPanel>
                )}
                {!isShareholder && !isPerson && (
                  <TabPanel value="shareHolders">
                    <Paper elevation={0}>
                      <TabComponent
                        entityType={entityType}
                        subjectType="shareHolder"
                        lastBeneficiaries={kyb?.lastBeneficiaries}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        setErrorAssign={setErrorAssign}
                        documentNumber={currentDocumentNumber}
                        handleFetch={handleFetch}
                        updateDetail={updateDetail}
                      />
                    </Paper>
                  </TabPanel>
                )}
                <TabPanel value="relatedPersons">
                  <Paper elevation={0}>
                    <TabComponent
                      entityType={entityType}
                      subjectType="relatedPerson"
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      setErrorAssign={setErrorAssign}
                      documentNumber={currentDocumentNumber}
                      handleFetch={handleFetch}
                    />
                  </Paper>
                </TabPanel>
                {(isShareholder || isPerson) && (
                  <TabPanel value="relatedCompanies">
                    <Paper elevation={0}>
                      <TabComponent
                        entityType={entityType}
                        subjectType="relatedCompany"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        setErrorAssign={setErrorAssign}
                        documentNumber={currentDocumentNumber}
                        handleFetch={handleFetch}
                      />
                    </Paper>
                  </TabPanel>
                )}
                {!isShareholder && (
                  <TabPanel value="assignedUsers">
                    <Paper elevation={0}>
                      <Box className="kyb-card">
                        <Box className="card-top">
                          <Typography variant="title">{t('common:assignedUsers')}</Typography>
                          {!isEditingAssign ? (
                            <Button
                              onClick={() => {
                                setIsEditingAssign(true)
                              }}
                              size="small"
                              variant="contained"
                            >
                              {t('common:addUser')}
                            </Button>
                          ) : (
                            <Fab variant="close" onClick={() => setIsEditingAssign(false)}>
                              <CloseOutlinedIcon />
                            </Fab>
                          )}
                        </Box>
                        <DatatableAssignUsers
                          rows={assignedUsers}
                          toAssign={toAssign}
                          isEditing={isEditingAssign}
                          updateAssigns={updateAssigns}
                          setIsEditingAssign={setIsEditingAssign}
                          errorAssign={errorAssign}
                        />
                      </Box>
                    </Paper>
                  </TabPanel>
                )}
                <TabPanel value="comments">
                  <Paper elevation={0}>
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:comments')}</Typography>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('common:email')}</TableCell>
                              <TableCell>{t('common:createdAt')}</TableCell>
                              <TableCell>{t('common:comment')}</TableCell>
                            </TableRow>
                          </TableHead>

                          {Array.isArray(kyb?.comments) && kyb?.comments?.length > 0 ? (
                            kyb.comments.map((row, index) => (
                              <TableBody key={index}>
                                <TableRow>
                                  <TableCell>
                                    <Box>{row.email ?? '---'}</Box>
                                  </TableCell>
                                  <TableCell>
                                    {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                  </TableCell>
                                  <TableCell>{row.description ?? '---'}</TableCell>
                                </TableRow>
                              </TableBody>
                            ))
                          ) : (
                            <Box className="table-custom-overlay">
                              <CustomNoRowsOverlay />
                            </Box>
                          )}
                        </Table>
                      </TableContainer>
                    </Box>
                  </Paper>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Box>

        <KybModal title="" open={showDownloadModal} setOpen={setShowDownloadModal}>
          <KybToPdf kyb={kyb} />
        </KybModal>

        <KybModal
          title={t('common:postComment')}
          subtitle={t('messages:commentDesc')}
          open={modalNewCommentOpen}
          setOpen={setModalNewCommentOpen}
        >
          <KybNewCommentModal onSubmit={postNewComment} />
        </KybModal>

        <KybModal title={`${t('common:edit')} KYB ${t('common:status')}`} open={modalOpen2} setOpen={setModalOpen2}>
          <KybEditStatusModal defaultValue={kyb?.status || 'NOT_STARTED'} onSubmit={handleUpdate} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} ${t('common:check')} ${t('common:status')}`}
          open={openModalCheckStatus}
          setOpen={setOpenModalCheckStatus}
        >
          <KybEditCheckStatusModal
            defaultValue={checkStatus}
            checkId={checkId}
            checkIndex={checkIndex}
            checkIndexMain={checkIndexMain}
            onSubmit={handleCheckUpdate}
          />
        </KybModal>

        <KybModal title={`${t('common:edit')} KYB ${t('common:riskLevel')}`} open={modalOpen5} setOpen={setModalOpen5}>
          <KybEditRiskLevelModal initialValue={kyb?.riskLevel || 'LOW'} onSubmit={handleUpdateRiskLevel} />
        </KybModal>

        <KybModal title={t('common:EDIT_CLUSTER')} open={modalEditCluster} setOpen={setModalEditCluster}>
          <KybClusterModal initialValue={kyb?.cluster ?? ''} onSubmit={handleUpdateKyb} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} ${t(`common:${modalEditField.label}`)}`}
          open={openModalEditField}
          setOpen={setOpenModalEditField}
        >
          <KybEditFieldModal
            label={modalEditField.label}
            initialValue={modalEditField.value}
            onSubmit={handleUpdateKyb}
          />
        </KybModal>
        <KybResetModal openModal={openModalKybReset} setOpenModal={setOpenModalKybReset} handleAction={handleRestart} />
      </Box>
    </Box>
  )
}

export default KybDetail
