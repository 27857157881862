import { createSlice } from '@reduxjs/toolkit'
import { searchTypes } from '../../pages/kyb/constants'

export const kybSlice = createSlice({
  name: 'kyb',
  initialState: {
    kybDetail: {},
    kybShareholder: {},
    toAssign: [],
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      status: 'ALL',
      showMyAssignments: false,
      searchType: searchTypes[0],
      searchText: '',
    },
  },
  reducers: {
    updateKybDetail: (state, action) => {
      state.kybDetail = action.payload
    },
    updateKybShareholder: (state, action) => {
      state.kybShareholder = action.payload
    },
    updateKybToAssign: (state, action) => {
      state.toAssign = action.payload
    },
    updateKybFilters: (state, action) => {
      state.filters = action.payload
    },
    addKybFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    }
  },
})

export const {
  updateKybDetail, updateKybFilters, updateKybToAssign, updateKybShareholder, addKybFilter
} = kybSlice.actions;

export default kybSlice.reducer
