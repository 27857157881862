import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import demoData from './demoData'
import { buildKybParams } from './builders'

const baseUrl = process.env.REACT_APP_BASEURL

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = getState().login
    headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  },
  fetchFn: async (...args) => {
    if (process.env.REACT_APP_IS_DEMO) {
      const url = new URL(args[0]?.url)
      const body = demoData[args[0]?.method][url?.pathname] || {}
      return new Response(JSON.stringify(body), {
        status: 200,
        statusText: 'OK',
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
      })
    }
    return fetch(...args)
  },
})

export const guenoApi = createApi({
  reducerPath: 'guenoApi',
  baseQuery,
  keepUnusedDataFor: 5,
  tagTypes: ['subjectsByKyb'],
  endpoints: (builder) => ({
    findKybs: builder.query({
      query: ({ limit, offset, page, sortField, sortDirection, countrySegment, ...query }) => {
        const searchParams = buildKybParams(query)

        return {
          url: `/kyb${countrySegment}`,
          params: {
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...searchParams,
          },
        }
      },
    }),
    createKyb: builder.mutation({
      query: ({ countrySegment, isKyb, body }) => {
        let url = `/kyb${countrySegment}`

        if (!isKyb) {
          url += 'subject'
        }

        return {
          url,
          method: 'POST',
          body,
        }
      },
    }),
    updateKyb: builder.mutation({
      query: ({ kybId, countrySegment, body }) => {
        const url = `/kyb${countrySegment}${kybId}`

        return {
          url,
          method: 'PATCH',
          body,
        }
      },
    }),
    updateKybLastBeneficiaries: builder.mutation({
      query: ({ kybId, documentNumber, operation }) => {
        const url = `/kyb/${kybId}/lastBeneficiary/${documentNumber}/${operation}`

        return {
          url,
          method: 'PATCH',
        }
      },
    }),
    findKycs: builder.query({
      query: ({ limit, offset, page, sortField, sortDirection, countrySegment, ...query }) => {
        const searchParams = buildKybParams(query)

        return {
          url: `/kyb${countrySegment}all/subjects`,
          params: {
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...searchParams,
          },
        }
      },
    }),
    findChecksByKybAndType: builder.query({
      query: ({ kybId, type, countrySegment }) => ({
        url: `/kyb${countrySegment}${kybId}/checks`,
        params: {
          type,
        },
      }),
    }),
    findUserMaster: builder.query({
      query: ({ emailClient }) => {
        return {
          url: `/usermaster/${emailClient}`,
        }
      },
    }),
    updateUserMaster: builder.mutation({
      query: ({ emailClient, body }) => {
        return {
          url: `/usermaster/${emailClient}`,
          method: 'PATCH',
          body,
        }
      },
    }),
    updateKybSubject: builder.mutation({
      query: ({ subjectId, body }) => {
        let url = `/kyb/subject/${subjectId}`

        return {
          url,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['subjectsByKyb'],
    }),
    deleteSubjectKyb: builder.mutation({
      query: ({ kybId, subjectId, relationType }) => {
        let url = `/kyb/${kybId}/subject/${subjectId}?relationType=${relationType}`

        return {
          url,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['subjectsByKyb'],
    }),
    findSubjectsByKyb: builder.query({
      query: ({ id, selectedTab, entityType, page, limit, sortField, sortDirection, countrySegment }) => {
        let url = `/kyb${countrySegment}${id}/${entityType}/${selectedTab}`
        return {
          url,
          params: {
            page,
            limit,
            sortField,
            sortDirection,
          },
        }
      },
      providesTags: ['subjectsByKyb'],
    }),
    getKybsReport: builder.mutation({
      query: ({ countrySegment, fromDate, endDate }) => ({
        url: `/kyb${countrySegment}report`,
        method: 'GET',
        params: {
          fromDate,
          endDate,
        },
        responseHandler: (response) => response.text(),
      }),
    }),
    runKyb: builder.mutation({
      query: ({ documentNumber, subjectId, countrySegment }) => {
        return {
          url: `/kyb${countrySegment}`,
          method: 'POST',
          body: {
            documentNumber,
            subjectId,
          },
        }
      },
    }),
    restartKyb: builder.mutation({
      query: ({ countrySegment, kybId }) => ({
        url: `/kyb${countrySegment}${kybId}/restart`,
        method: 'PATCH',
        body: {},
      }),
    }),
    findKytUserActions: builder.query({
      query: ({ id, page, limit }) => {
        let url = `/kyt/userActions/${id}`
        return {
          url,
          params: {
            page,
            limit,
          },
        }
      },
    }),
    getKybNotifications: builder.query({
      query: ({ countrySegment, ...params }) => ({
        url: `/kyb${countrySegment}all/notifications`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useFindKybsQuery,
  useFindKycsQuery,
  useCreateKybMutation,
  useUpdateKybMutation,
  useUpdateKybLastBeneficiariesMutation,
  useLazyFindChecksByKybAndTypeQuery,
  useFindUserMasterQuery,
  useUpdateUserMasterMutation,
  useUpdateKybSubjectMutation,
  useDeleteSubjectKybMutation,
  useFindSubjectsByKybQuery,
  useGetKybsReportMutation,
  useRunKybMutation,
  useRestartKybMutation,
  useFindKytUserActionsQuery,
  useGetKybNotificationsQuery,
} = guenoApi

export default guenoApi
