import './datatable.scss'
import { Autocomplete, Box, TextField, Link, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Spinner from '../spinner/spinner'
import { useDispatch, useSelector } from 'react-redux'
import { useBatchList } from '../../../pages/pages-hooks/useBatches'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { FilterModal } from '../../utilities/FilterModal'
import { updateFilters } from '../../../features/batch/batchSlice'
import DeleteIcon from '@mui/icons-material/Delete'

function DatatableBatch({ isLoading, setIsLoading }) {
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const { batches } = useSelector((state) => state.batch)

  useBatchList(setIsLoading, isLoading)

  const handleBatchDelte = (id) => {
    const urlUser = `${process.env.REACT_APP_BASEURL}/batch/deepDelete/${id}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(urlUser, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
        setIsLoading(true)
      })
      .catch((error) => {
        console.error('[UPDATE WORKFLOW ERROR] --> ', error)
        setIsLoading(true)
      })
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Batch ID',
      flex: 1,
      minWidth: 230,
      renderCell: (params) => (
        <Link href={`/batchDetails/${params.id}`}>
          <Typography variant="number">{params.row.id}</Typography>
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      flex: 1,
      minWidth: 230,
      renderCell: (params) => (
        <Button
          disabled={!activeServices?.includes('batchdeepDelete:batchId')}
          variant="outlinedError"
          onClick={() => {
            handleBatchDelte(params?.id)
          }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ]

  const handleResetButton = () => {
    if (status !== '') setStatus('')
    if (type !== '') setType('')
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateFilters({
        page: 0,
        limit: 50,
        offset: 0,
        type: '',
        status: '',
      }),
    )
  }

  const handleDispatch = () => {
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateFilters({
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        type,
        status,
      }),
    )
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'}`}>
      {isLoading && <Spinner noTransform />}
      <DataGridComponent
        rows={Array.isArray(batches?.data) && batches.data.length > 0 ? batches.data : []}
        columns={columns}
        count={batches?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:type')}:
            </Typography>

            <Autocomplete
              size="small"
              value={type}
              onChange={(event, value) => {
                if (value) {
                  setType(value)
                } else {
                  setType(null)
                }
              }}
              disablePortal
              options={['CONSUMER', 'BUSINESS', 'TRANSACTION']}
              renderInput={(params) => <TextField variant="outlined" {...params} placeholder={t('common:type')} />}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>

            <Autocomplete
              size="small"
              value={status}
              onChange={(event, value) => {
                if (value) {
                  setStatus(value)
                } else {
                  setStatus(null)
                }
              }}
              disablePortal
              options={['CREATED', 'PROCESSING', 'READY', 'ERROR', 'PROCESSED', 'PARTIAL_PROCESSED', 'DISCARDED']}
              renderInput={(params) => <TextField variant="outlined" {...params} placeholder={t('common:status')} />}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default DatatableBatch
