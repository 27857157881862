import './datatable.scss'
import { Box, Button, Fab, Link, Modal, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertFetchSpinner from '../../common/alertFetchSpinner/alertFetchSpinner'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useTranslation } from 'react-i18next'
import { updateRiskMatrix } from '../../../features/riskMatrix/riskMatrixSlice'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const DatatableRiskMatrix = ({ page, setPage, rowsPerPage, setRowsPerPage, setModalOpen, setIsLoading }) => {
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const riskMatrixState = useSelector((state) => state.riskMatrix?.riskMatrixs)
  const queryState = useSelector((state) => state.riskMatrix.filters)
  const [riskMatrixs, setRiskMatrixs] = useState([])
  const [idToDelete, setIdToDelete] = useState('')
  const [message, setMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [open2, setOpen2] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const handleClose2 = () => {
    setIsLoading(false)
    setOpen2(false)
    setMessage('')
    setIdToDelete('')
  }

  const handleRuskMatrixView = (name) => {
    navigate(`/admin/riskMatrix/${name}`)
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleOpen2 = (name) => {
    setOpen2(true)
    setMessage(`${t('messages:deleteMatrixSelected')}: ${name}?`)
  }

  const handleDelete = (id) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminRoles = `${process.env.REACT_APP_BASEURL}/riskmatrix/${id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlAdminRoles, options)
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)

            updateMatrixList()
            handleClose2()
            handleFetch(false, res.message)
          }
        })
        .catch((error) => {
          console.error('[DELETE RISK MATRIX ERROR] --> ', error)
          handleClose2()
          handleFetch(true, error.message ? error.message : t('messages:deletionError'))
        })
    } else {
      updateMatrixList()
      handleClose2()
      handleFetch(false, t('messages:deletionSuccess'))
    }
  }

  const updateMatrixList = () => {
    const { limit, offset, fromDate, toDate, page, name, status } = queryState
    const baseUrlWebhooks = `${process.env.REACT_APP_BASEURL}/riskmatrix`

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
      if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
      if (name?.length) url += `&name=${name}`
      if (status?.length) url += `&status=${status}`

      return url
    }
    let url = buildUrl(baseUrlWebhooks)

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateRiskMatrix(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => console.error('[UPDATE: risk matrix] --> ', error))
  }

  const handleUpdateMatrixStatus = (e, row) => {
    setIsLoading(true)
    e.preventDefault()
    let data = {
      status: row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    }

    const urlUser = `${process.env.REACT_APP_BASEURL}/riskmatrix/${row._id}`
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }

    fetch(urlUser, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
        updateMatrixList()
        handleFetch(false, res.message)
      })
      .catch((error) => {
        console.error('[UPDATE RISK MATRIX ERROR] --> ', error)
        setIsLoading(false)
        handleFetch(true, error.message ? error.message : `${t('common:riskMatrix')} ${t('messages:updateError')}`)
      })
  }

  useEffect(() => {
    setRiskMatrixs(riskMatrixState)
    setIsLoading(false)
  }, [riskMatrixState])

  const columns = [
    {
      field: 'name',
      headerName: t('common:name'),
    },
    {
      field: 'description',
      headerName: t('common:description'),
    },
    {
      field: 'target',
      headerName: t('common:target'),
    },
    {
      field: 'conditions',
      headerName: t('common:conditions'),
      renderCell: (params) => {
        return <Box>{params.row.params?.length}</Box>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return (
          <Box className="user-admin-flex">
            {params.row.status}
            <Switch
              key={params.row.id}
              checked={params.row.status === 'ACTIVE' ? true : false}
              onChange={(e) => {
                handleUpdateMatrixStatus(e, params.row)
              }}
            />
          </Box>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            title={params.row._id}
            onClick={() => {
              handleRuskMatrixView(params.row.name)
            }}
          >
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
    {
      field: 'edit',
      headerName: t('common:actions'),
      hide: !activeServices.includes('riskmatrix:id'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="outlinedError"
            onClick={() => {
              const id = riskMatrixs?.data?.find((rm) => rm._id === params.row._id)._id
              setIdToDelete(id)
              handleOpen2(params.row.name)
            }}
          >
            <DeleteIcon />
          </Button>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      <DataGridComponent
        rows={(Array.isArray(riskMatrixs?.data) && riskMatrixs?.data?.length && riskMatrixs?.data) || []}
        columns={columns}
        count={riskMatrixs?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />

      <Modal open={open2} onClose={handleClose2}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:delete')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => handleClose2()}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle">{message}</Typography>
            <Typography variant="subtitle">{t('messages:actionUndone')}</Typography>
            <Box className="modal-filter-buttons">
              <Button variant="outlined" onClick={handleClose2}>
                {t('common:no')}
              </Button>
              <Button variant="contained" onClick={() => handleDelete(idToDelete)}>
                {t('common:yes')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DatatableRiskMatrix
