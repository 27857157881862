import React, { useEffect, useState } from 'react'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { Box } from '@mui/material'

const MapComponent = ({ fixedAddress, onCoordinatesFetched }) => {
  const [coordinates, setCoordinates] = useState(null)

  const mapContainerStyle = {
    height: '120px',
    width: '100%',
    borderRadius: '10px',
  }

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        if (!fixedAddress?.includes(', -, -, -, ')) {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fixedAddress)}&key=${
              process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }`,
          )

          const data = await response.json()
          const result = data.results[0]

          if (result) {
            const { lat, lng } = result.geometry.location
            setCoordinates({ lat, lng })
            if (onCoordinatesFetched) {
              onCoordinatesFetched({ lat, lng })
            }
            return
          }
        }
      } catch (error) {
        console.error('Error fetching geocoding data', error)
      }
    }

    geocodeAddress()
  }, [fixedAddress])

  return (
    <Box>
      {coordinates && (
        <GoogleMap mapContainerStyle={mapContainerStyle} center={coordinates} zoom={16}>
          <MarkerF position={coordinates} />
        </GoogleMap>
      )}
    </Box>
  )
}

export default MapComponent
