import { createSelector, createSlice } from '@reduxjs/toolkit'
import { getCountrySegment } from '../../pages/kyb/kyb.hook-helpers'
import guenoApi from '../../services/guenoApi'

export const kybCountrySlice = createSlice({
  name: 'kybCountry',
  initialState: {
    currentCountry: '',
    countrySegment: '',
    isLoading: false,
  },
  reducers: {
    setCurrentCountry(state, action) {
      state.currentCountry = action.payload
      state.countrySegment = getCountrySegment(action.payload)
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(guenoApi.endpoints.findUserMaster.matchFulfilled, (state, { payload }) => {
      let currentCountry = 'BR'

      if (payload?.kybCountries?.length > 1) {
        currentCountry = payload?.kybCountries[0]
      }

      state.currentCountry = currentCountry
      state.countrySegment = getCountrySegment(currentCountry)
    })
  },
})

export const { setCurrentCountry, setIsLoading } = kybCountrySlice.actions

export const selectAvailableCountries = createSelector(
  (state, emailClient) => guenoApi.endpoints.findUserMaster.select({ emailClient })(state),
  (state) => ({
    countries: Array.isArray(state?.data?.kybCountries) ? state?.data?.kybCountries : ['BR'],
    isLoading: state.isLoading || state.isFetching,
  }),
)

export default kybCountrySlice.reducer
