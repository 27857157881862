import './pdf.scss'
import html2pdf from 'html2pdf.js'
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const KybToPdf = ({ kyb }) => {
  const { email } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])
  const [kybDetails, setKybDetails] = useState(true)
  const [companyDetails, setCompanyDetails] = useState(true)
  const [shareholders, setShareholders] = useState(true)
  const [timeline, setTimeline] = useState(false)
  const [assignedUsers, setAssignedUsers] = useState(false)
  const [jap, setJap] = useState(false)
  const [debtors, setDebtors] = useState(false)
  const [domains, setDomains] = useState(false)
  const [kyc, setKyc] = useState(false)
  const [negativeMedia, setNegativeMedia] = useState(false)
  const [cdn, setCdn] = useState(false)
  const [comments, setComments] = useState(false)
  const [serasa, setSerasa] = useState(false)
  const [cnep, setCnep] = useState(false)
  const [cepim, setCepim] = useState(false)
  const [ceis, setCeis] = useState(false)
  const [leniencyAgreements, setLeniencyAgreements] = useState(false)
  const [cade, setCade] = useState(false)
  const [lawsuits, setLawsuits] = useState(false)

  const [serasaCheck, setSerasaCheck] = useState({})
  const [cnepCheck, setCnepCheck] = useState({})
  const [cepimCheck, setCepimCheck] = useState({})
  const [ceisCheck, setCeisCheck] = useState({})
  const [leniencyAgreementsCheck, setLeniencyAgreementsCheck] = useState({})
  const [debtorsCheck, setDebtorsCheck] = useState({})
  const [domainsCheck, setDomainsCheck] = useState([])
  const [kycCheck, setKycCheck] = useState([])
  const [cdnCheck, setCdnCheck] = useState([])
  const [negativeMediaCheck, setNegativeMediaCheck] = useState([])
  const [cadeCheck, setCadeCheck] = useState([])
  const [lawsuitsCheck, setLawsuitsCheck] = useState([])
  const [japCheck, setJapCheck] = useState([])

  useEffect(() => {
    if (Array.isArray(kyb?.jap?.checkDetails) && kyb?.jap?.checkDetails[0]?.Lawsuits) {
      setJapCheck(kyb?.jap?.checkDetails[0]?.Lawsuits)
      setJap(true)
    }
    if (!Array.isArray(kyb?.jap?.checkDetails) && kyb?.jap?.checkDetails?.Lawsuits) {
      setJapCheck(kyb?.jap?.checkDetails.Lawsuits)
      setJap(true)
    }

    if (kyb?.serasa?.checkDetails) {
      setSerasaCheck(kyb?.serasa?.checkDetails)
      setSerasa(true)
    }
    if (kyb?.cnep?.checkDetails) {
      setCnepCheck(kyb?.cnep?.checkDetails)
      setCnep(true)
    }
    if (kyb?.cepim?.checkDetails) {
      setCepimCheck(kyb?.cepim?.checkDetails)
      setCepim(true)
    }
    if (kyb?.ceis?.checkDetails) {
      setCeisCheck(kyb?.ceis?.checkDetails)
      setCeis(true)
    }
    if (kyb?.leniencyAgreements?.checkDetails) {
      setLeniencyAgreementsCheck(kyb?.leniencyAgreements?.checkDetails)
      setLeniencyAgreements(true)
    }
    if (Array.isArray(kyb?.debtors?.checkDetails) && kyb?.debtors?.checkDetails[0]?.GovernmentDebtors) {
      setDebtorsCheck(kyb.debtors.checkDetails[0].GovernmentDebtors)
      setDebtors(true)
    }
    if (!Array.isArray(kyb?.debtors?.checkDetails) && kyb?.debtors?.checkDetails?.GovernmentDebtors) {
      setDebtorsCheck(kyb.debtors.checkDetails.GovernmentDebtors)
      setDebtors(true)
    }
    if (Array.isArray(kyb?.domains?.checkDetails) && kyb?.domains?.checkDetails[0]?.Domains) {
      setDomainsCheck(kyb?.domains?.checkDetails[0]?.Domains)
      setDomains(true)
    }
    if (!Array.isArray(kyb?.domains?.checkDetails) && kyb?.domains?.checkDetails?.Domains) {
      setDomainsCheck(kyb?.domains?.checkDetails?.Domains)
      setDomains(true)
    }
    if (Array.isArray(kyb?.kyc?.checkDetails) && kyb?.kyc?.checkDetails[0]?.KycData) {
      setKycCheck(kyb?.kyc?.checkDetails[0]?.KycData)
      setKyc(true)
    }
    if (!Array.isArray(kyb?.kyc?.checkDetails) && kyb?.kyc?.checkDetails?.KycData) {
      setKycCheck(kyb?.kyc?.checkDetails?.KycData)
      setKyc(true)
    }
    if (kyb?.cdn?.checkDetails) {
      setCdnCheck(kyb?.cdn?.checkDetails)
      setCdn(true)
    }
    if (
      Array.isArray(kyb?.negativeMedia?.checkDetails) &&
      kyb?.negativeMedia?.checkDetails[0]?.MediaProfileAndExposure
    ) {
      setNegativeMediaCheck(kyb?.negativeMedia?.checkDetails[0]?.MediaProfileAndExposure)
      setNegativeMedia(true)
    }
    if (!Array.isArray(kyb?.negativeMedia?.checkDetails) && kyb?.negativeMedia?.checkDetails?.MediaProfileAndExposure) {
      setNegativeMediaCheck(kyb?.negativeMedia?.checkDetails?.MediaProfileAndExposure)
      setNegativeMedia(true)
    }
    if (kyb?.cade?.checkDetails) {
      setCadeCheck(kyb?.cade?.checkDetails[0])
      setCade(true)
    }
    if (kyb?.lawsuits?.checkDetails) {
      setLawsuitsCheck(kyb?.lawsuits?.checkDetails)
      setLawsuits(true)
    }
    if (kyb?.assignedUsers?.length) setAssignedUsers(true)
    if (kyb?.comments?.length) setComments(true)
    if (kyb?.timeline?.length) setTimeline(true)
  }, [kyb])

  const generatePDF = () => {
    const element = document.getElementById('contentToConvert') // ID of the HTML element
    const options = {
      margin: [20, 15],
      filename: `${kyb?.companyDetails?.name}_${new Date().getTime()}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    }
    const today = new Date()
    html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        let totalPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= totalPages; i++) {
          // set footer to every page
          pdf.setPage(i)
          // set footer font
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
          if (i === 1) {
            pdf.text(
              pdf.internal.pageSize.getWidth() - 580,
              pdf.internal.pageSize.getHeight() - 825,
              `Güeno - PDF Generated on ${buildTimeStringFromTimestamp(today)} by ${email} - ${today.getTime()}`,
            )
          }
          pdf.text(
            pdf.internal.pageSize.getWidth() - 60,
            pdf.internal.pageSize.getHeight() - 10,
            `Page ${i} of ${totalPages}`,
          )
        }
      })
      .save()
  }

  const renderCheckGeneralDetails = (check) => {
    return (
      <Box className="detail-group-grid-4">
        <Box>
          <span>{t('common:executedBy')}</span>
          <p>{check?.email ?? '---'}</p>
        </Box>
        <Box>
          <span>{t('common:status')}</span>
          <p>{check?.status ?? '---'}</p>
        </Box>
        <Box>
          <span>{t('common:creationDate')}</span>
          <p>{buildTimeStringFromTimestamp(check?.createdAt) ?? '---'}</p>
        </Box>
        <Box>
          <span>{t('common:lastUpdated')}</span>
          <p>{buildTimeStringFromTimestamp(check?.updatedAt) ?? '---'}</p>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box className="modal-kyb-head">
        <Button onClick={generatePDF} variant="contained" size="small" color="secondary">
          {t('common:exportToPdf')}
        </Button>
      </Box>

      <Box className="content-to-convert-container">
        <Box className="detail-buttons">
          <Box className="detail-group-flow">
            <FormControlLabel
              control={<Checkbox checked={kybDetails} />}
              label={t('common:kybDetails')}
              onChange={() => setKybDetails(!kybDetails)}
            />
            <FormControlLabel
              control={<Checkbox checked={companyDetails} />}
              label={t('common:companyDetails')}
              onChange={() => setCompanyDetails(!companyDetails)}
            />
            {kyb?.shareHolders && (
              <FormControlLabel
                control={<Checkbox checked={shareholders} />}
                label={t('common:shareholders')}
                onChange={() => setShareholders(!shareholders)}
              />
            )}
            {kyb?.assignedUsers && (
              <FormControlLabel
                control={<Checkbox checked={assignedUsers} />}
                label={t('common:assignedUsers')}
                onChange={() => setAssignedUsers(!assignedUsers)}
              />
            )}
            {kyb?.domains && (
              <FormControlLabel
                control={<Checkbox checked={domains} />}
                label={t('common:domains')}
                onChange={() => setDomains(!domains)}
              />
            )}
            {kyb?.kyc && (
              <FormControlLabel control={<Checkbox checked={kyc} />} label={'KYC'} onChange={() => setKyc(!kyc)} />
            )}
            {kyb?.negativeMedia && (
              <FormControlLabel
                control={<Checkbox checked={negativeMedia} />}
                label={t('common:negativeMedia')}
                onChange={() => setNegativeMedia(!negativeMedia)}
              />
            )}

            {kyb?.serasa && (
              <FormControlLabel
                control={<Checkbox checked={serasa} />}
                label={t('common:serasaScore')}
                onChange={() => setSerasa(!serasa)}
              />
            )}
            {kyb?.cnep && (
              <FormControlLabel control={<Checkbox checked={cnep} />} label={'CNEP'} onChange={() => setCnep(!cnep)} />
            )}
            {kyb?.cepim && (
              <FormControlLabel
                control={<Checkbox checked={cepim} />}
                label={'CEPIM'}
                onChange={() => setCepim(!cepim)}
              />
            )}
            {kyb?.ceis && (
              <FormControlLabel control={<Checkbox checked={ceis} />} label={'CEIS'} onChange={() => setCeis(!ceis)} />
            )}
            {kyb?.leniencyAgreements && (
              <FormControlLabel
                control={<Checkbox checked={leniencyAgreements} />}
                label={t('common:leniencyAgreements')}
                onChange={() => setLeniencyAgreements(!leniencyAgreements)}
              />
            )}
            {kyb?.debtors && (
              <FormControlLabel
                control={<Checkbox checked={debtors} />}
                label={t('common:governmentDebtors')}
                onChange={() => setDebtors(!debtors)}
              />
            )}
            {kyb?.cdn && (
              <FormControlLabel
                control={<Checkbox checked={cdn} />}
                label={t('common:cdn')}
                onChange={() => setCdn(!cdn)}
              />
            )}
            {kyb?.jap && (
              <FormControlLabel control={<Checkbox checked={jap} />} label={'JAP'} onChange={() => setJap(!jap)} />
            )}
            {kyb?.cade && (
              <FormControlLabel control={<Checkbox checked={cade} />} label={'CADE'} onChange={() => setCade(!cade)} />
            )}
            {kyb?.lawsuits && (
              <FormControlLabel
                control={<Checkbox checked={lawsuits} />}
                label={t('common:lawsuits')}
                onChange={() => setLawsuits(!lawsuits)}
              />
            )}
            {kyb?.comments && (
              <FormControlLabel
                control={<Checkbox checked={comments} />}
                label={t('common:comments')}
                onChange={() => setComments(!comments)}
              />
            )}
            {kyb?.timeline && (
              <FormControlLabel
                control={<Checkbox checked={timeline} />}
                label={t('common:timeline')}
                onChange={() => setTimeline(!timeline)}
              />
            )}
          </Box>
        </Box>
        <Box id="contentToConvert" className="content-to-convert">
          <h1>{kyb?.companyDetails?.name ? kyb?.companyDetails?.name : kyb?._id ? kyb?._id : '---'} KYB</h1>
          {kybDetails && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:kybDetails')}</h3>
              <Box className="detail-group-grid-2">
                <Box>
                  <span>ID</span>
                  <p>{kyb?._id}</p>
                </Box>
                <Box>
                  <span>{t('common:status')}</span>
                  <p>{kyb?.status}</p>
                </Box>
                <Box>
                  <span>{t('common:creationDate')}</span>
                  <p>{kyb?.createdAt}</p>
                </Box>
                <Box>
                  <span>{t('common:lastUpdated')}</span>
                  <p>{kyb?.updatedAt}</p>
                </Box>
              </Box>
            </Box>
          )}
          {companyDetails && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:companyDetails')}</h3>
              <Box className="detail-group-grid-4">
                <Box>
                  <span>{t('common:companyDetails')}</span>
                  <p>{kyb?.companyDetails?.name ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:documentNumber')}</span>
                  <p>{kyb?.companyDetails?.cnpj ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:dateOfRegistration')}</span>
                  <p>{kyb?.companyDetails?.dateOfRegistration ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:country')}</span>
                  <p>{kyb?.companyDetails?.country ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:situation')}</span>
                  <p>{kyb?.companyDetails?.situacao?.nome ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:legalNature')}</span>
                  <p>{kyb?.companyDetails?.naturezaJuridica?.descricao ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:size')}</span>
                  <p>{kyb?.companyDetails?.porte?.descricao ?? '---'}</p>
                </Box>
              </Box>
              {Array.isArray(kyb?.companyDetails?.addresses) && (
                <Box>
                  <h4>{t('common:address')}</h4>
                  <Box className="detail-group-grid-4">
                    <Box>
                      <span>{t('common:postcode')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.postcode ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:street')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.street ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:number')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.number ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:aptSuite')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.apt_suite ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:city')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.city ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:state')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.state ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:country')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.country ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {companyDetails && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:companyEconomicActivity')}</h3>
              <Box className="detail-group-grid-2">
                <Box>
                  <span>
                    {t('common:mainActivity')} {t('common:class')}
                  </span>
                  <p>{kyb?.companyDetails?.cnae?.fiscal ?? '---'}</p>
                </Box>
                <Box>
                  <span>
                    {t('common:mainActivity')} {t('common:description')}
                  </span>
                  <p>{kyb?.companyDetails?.cnae?.descricao ?? '---'}</p>
                </Box>
              </Box>
              {kyb?.companyDetails?.cnae?.secundarias && (
                <Box className="group-table">
                  <span>
                    {t('common:secondaryActivity')} {t('common:class')}
                  </span>
                  <table className="bs bst group-table">
                    <thead>
                      <tr>
                        <th className="bs">ID</th>
                        <th className="bs">{t('common:description')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb.companyDetails.cnae.secundarias.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td className="bs table-cell col-2-small">{element.id}</td>
                            <td className="bs table-cell col-2-big">{element.descricao}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
          {shareholders && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:shareholders')}</h3>
              {kyb?.shareHolders && (
                <Box className="group-table">
                  <table className="bs bst group-table">
                    <thead>
                      <tr className="group-table">
                        <th className="bs">{t('common:name')}</th>
                        <th className="bs">{t('common:document')}</th>
                        <th className="bs">{t('common:country')}</th>
                        <th className="bs">{t('common:type')}</th>
                        <th className="bs">{t('common:relationship')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb.shareHolders.map((element, index) => {
                        return (
                          <tr className="group-table" key={index}>
                            <td className="bs table-cell col-5">{element.name}</td>
                            <td className="bs table-cell col-5">{element.documentNumber}</td>
                            <td className="bs table-cell col-5">{element.nationality}</td>
                            <td className="bs table-cell col-5">{element.type}</td>
                            <td className="bs table-cell col-5">
                              {element.relationship?.type ?? '---'} - {element.relationship?.name ?? '---'}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}

          {cnep && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:cnep')}</h3>
              {renderCheckGeneralDetails(kyb?.cnep)}
              {!Array.isArray(cnepCheck) && Object.keys(cnepCheck).length && (
                <Box>
                  <Box>
                    <span>{t('common:information')}</span>
                    <p>{cnepCheck.info ?? '---'}</p>
                  </Box>
                </Box>
              )}
              {Array.isArray(cnepCheck) && cnepCheck.length && (
                <Box>
                  <h4>{t('common:sanctions')}</h4>
                  <Box className="group-table">
                    <table className="bs bst group-table">
                      <thead>
                        <tr className="group-table">
                          <th className="bs">{t('common:initialDate')}</th>
                          <th className="bs">{t('common:endDate')}</th>
                          <th className="bs">{t('common:sanctionDescription')}</th>
                          <th className="bs">{t('common:sanctioningBody')}</th>
                          <th className="bs">{t('common:fineValue')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cnepCheck.map((element, index) => {
                          return (
                            <tr className="group-table" key={index}>
                              <td className="bs table-cell col-5">{element.dataInicioSancao ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.dataFimSancao ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.tipoSancao?.descricaoResumida ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.orgaoSancionador?.nome ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.valorMulta ?? '---'}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {cepim && (
            <Box className="detail-group">
              <h3 className="detail-group-title">CEPIM</h3>
              {renderCheckGeneralDetails(kyb?.cepim)}
              {!Array.isArray(cepimCheck) && Object.keys(cepimCheck).length && (
                <Box>
                  <Box>
                    <span>{t('common:information')}</span>
                    <p>{cepimCheck.info ?? '---'}</p>
                  </Box>
                </Box>
              )}
              {Array.isArray(cepimCheck) && cepimCheck.length && (
                <Box>
                  <h4>{t('common:sanctions')}</h4>
                  <Box className="group-table">
                    <table className="bs bst group-table">
                      <thead>
                        <tr className="group-table">
                          <th className="bs">{t('common:date')}</th>
                          <th className="bs">{t('common:reason')}</th>
                          <th className="bs">{t('common:higherBody')}</th>
                          <th className="bs">{t('common:legalPerson')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cepimCheck.map((element, index) => {
                          return (
                            <tr className="group-table" key={index}>
                              <td className="bs table-cell col-4">{element.dataReferencia ?? '---'}</td>
                              <td className="bs table-cell col-4">{element.motivo ?? '---'}</td>
                              <td className="bs table-cell col-4">{element.orgaoSuperior?.nome ?? '---'}</td>
                              <td className="bs table-cell col-4">{element.pessoaJuridica?.nome ?? '---'}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {ceis && (
            <Box className="detail-group">
              <h3 className="detail-group-title">CEIS</h3>
              {renderCheckGeneralDetails(kyb?.ceis)}
              {!Array.isArray(ceisCheck) && Object.keys(ceisCheck).length && (
                <Box>
                  <Box>
                    <span>{t('common:information')}</span>
                    <p>{ceisCheck.info ?? '---'}</p>
                  </Box>
                </Box>
              )}
              {Array.isArray(ceisCheck) && ceisCheck.length && (
                <Box>
                  <h4>{t('common:sanctions')}</h4>
                  <Box className="group-table">
                    <table className="bs bst group-table">
                      <thead>
                        <tr className="group-table">
                          <th className="bs">{t('common:initialDate')}</th>
                          <th className="bs">{t('common:endDate')}</th>
                          <th className="bs">{t('common:sanctionDescription')}</th>
                          <th className="bs">{t('common:sanctioningBody')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ceisCheck.map((element, index) => {
                          return (
                            <tr className="group-table" key={index}>
                              <td className="bs table-cell col-4">{element.dataInicioSancao ?? '---'}</td>
                              <td className="bs table-cell col-4">{element.dataFimSancao ?? '---'}</td>
                              <td className="bs table-cell col-4">{element.tipoSancao?.descricaoResumida ?? '---'}</td>
                              <td className="bs table-cell col-4">{element.orgaoSancionador?.nome ?? '---'}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {leniencyAgreements && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:leniencyAgreements')}</h3>
              {renderCheckGeneralDetails(kyb?.leniencyAgreements)}
              {!Array.isArray(leniencyAgreementsCheck) && Object.keys(leniencyAgreementsCheck).length && (
                <Box>
                  <Box>
                    <span>{t('common:information')}</span>
                    <p>{leniencyAgreementsCheck.info ?? '---'}</p>
                  </Box>
                </Box>
              )}
              {Array.isArray(leniencyAgreementsCheck) && leniencyAgreementsCheck.length && (
                <Box>
                  <h4>{t('common:sanctions')}</h4>
                  <Box className="group-table">
                    <table className="bs bst group-table">
                      <thead>
                        <tr className="group-table">
                          <th className="bs">{t('common:initialDate')}</th>
                          <th className="bs">{t('common:endDate')}</th>
                          <th className="bs">{t('common:responsibleBody')}</th>
                          <th className="bs">{t('common:agreementSituation')}</th>
                          <th className="bs">{t('common:amount')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leniencyAgreementsCheck.map((element, index) => {
                          return (
                            <tr className="group-table" key={index}>
                              <td className="bs table-cell col-5">{element.dataInicioAcordo ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.dataFimAcordo ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.orgaoResponsavel ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.situacaoAcordo ?? '---'}</td>
                              <td className="bs table-cell col-5">{element.quantidade ?? '---'}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {jap && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:lawsuits')}</h3>
              {renderCheckGeneralDetails(kyb?.jap)}
              {Object.keys(japCheck).length && (
                <Box className="">
                  <h4>
                    {t('common:lawsuits')} {t('common:details')}
                  </h4>
                  <Box className="detail-group-grid-5">
                    <Box>
                      <span>{t('common:totalLawsuits')}</span>
                      <p>{japCheck.TotalLawsuits ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalLawsuits')} {t('common:asAuthor')}
                      </span>
                      <p>{japCheck.TotalLawsuitsAsAuthor ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalLawsuits')} {t('common:asDefendant')}
                      </span>
                      <p>{japCheck.TotalLawsuitsAsDefendant ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalLawsuits')} {t('common:asOther')}
                      </span>
                      <p>{japCheck.TotalLawsuitsAsOther ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:firstLawsuitDate')}</span>
                      <p>{japCheck.FirstLawsuitDate ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last30')} {t('common:lawsuits')}
                      </span>
                      <p>{japCheck.Last30DaysLawsuits ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last90')} {t('common:lawsuits')}
                      </span>
                      <p>{japCheck.Last90DaysLawsuits ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last180')} {t('common:lawsuits')}
                      </span>
                      <p>{japCheck.Last180DaysLawsuits ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last365')} {t('common:lawsuits')}
                      </span>
                      <p>{japCheck.Last365DaysLawsuits ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:lastLawsuitDate')}</span>
                      <p>{japCheck.LastLawsuitDate ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {cade && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:cade')}</h3>
              {cadeCheck && typeof cadeCheck === 'object' && Object.keys(cadeCheck).length > 0 && (
                <Box className="">
                  <h4>
                    {t('common:check')} {t('common:details')}
                  </h4>
                  <Box className="detail-group-grid-5">
                    <Box>
                      <span>Data Registro</span>
                      <p>{cadeCheck.data_registro ?? '---'}</p>
                    </Box>

                    <Box>
                      <span>Interessados</span>
                      <p>{cadeCheck.interessados ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>Processo</span>
                      <p>{cadeCheck.processo ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>Tipo</span>
                      <p>{cadeCheck.tipo ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>Url</span>
                      <p>{cadeCheck.url ?? '---'}</p>
                    </Box>
                  </Box>
                  <Box className="cade-wrapper">
                    <h4>Andamentos</h4>
                    <Box className="group-table">
                      <table className="bs bst group-table">
                        <thead>
                          <tr className="group-table">
                            <th className="bs">Data/Hora:</th>
                            <th className="bs">Descricao:</th>
                            <th className="bs">Unidade:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cadeCheck.andamentos &&
                            Array.isArray(cadeCheck?.andamentos) &&
                            cadeCheck?.andamentos?.map((andamento, index) => {
                              return (
                                <tr className="group-table" key={index}>
                                  <td className="bs table-cell col-5">{andamento.datahora}</td>
                                  <td className="bs table-cell col-5">{andamento.descricao}</td>
                                  <td className="bs table-cell col-5">{andamento.unidade}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </Box>
                  </Box>

                  <Box className="cade-wrapper">
                    <h4>Protocolos</h4>
                    <Box className="group-table">
                      <table className="bs bst group-table">
                        <thead>
                          <tr className="group-table">
                            <th className="bs">Data:</th>
                            <th className="bs">Registro:</th>
                            <th className="bs">Documento:</th>
                            <th className="bs">Tipo:</th>
                            <th className="bs">Unidade:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cadeCheck.andamentos &&
                            Array.isArray(cadeCheck?.andamentos) &&
                            cadeCheck?.protocolos?.map((protocolo, index) => {
                              return (
                                <tr className="group-table" key={index}>
                                  <td className="bs table-cell col-5">{protocolo.data}</td>
                                  <td className="bs table-cell col-5">{protocolo.data_registro}</td>
                                  <td className="bs table-cell col-5">{protocolo.documento}</td>
                                  <td className="bs table-cell col-5">{protocolo.tipo}</td>
                                  <td className="bs table-cell col-5">{protocolo.unidade}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {debtors && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:governmentDebtors')}</h3>
              {renderCheckGeneralDetails(kyb?.debtors)}
              {Object.keys(debtorsCheck).length && (
                <Box className="">
                  <h4>{t('common:debtorsDetails')}</h4>
                  <Box className="detail-group-grid-3">
                    <Box>
                      <span>{t('common:debtorName')}</span>
                      <p>{debtorsCheck.DebtorName ?? 'No debts'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:totalDebtValue')}</span>
                      <p>{debtorsCheck.TotalDebtValue ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:totalDebts')}</span>
                      <p>{debtorsCheck.TotalDebts ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {domains && (
            <Box className="detail-group">
              <h3 className="detail-group-title">Website {t('common:data')}</h3>
              {renderCheckGeneralDetails(kyb?.domains)}
              {Array.isArray(domainsCheck) && domainsCheck.length && (
                <Box className="">
                  <h4>Websites {t('common:details')}</h4>
                  <Box className="group-table">
                    <table className="bs bst group-table">
                      <thead>
                        <tr className="group-table">
                          <th className="bs">{t('common:domain')}</th>
                          <th className="bs">{t('common:class')}</th>
                          <th className="bs">{t('common:mainSegment')}</th>
                          <th className="bs">{t('common:visitorRange')}</th>
                          <th className="bs">{t('common:socialNetworks')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {domainsCheck.map((element, index) => {
                          return (
                            <tr className="group-table" key={index}>
                              <td className="bs table-cell col-5">{element.FinalDomainName}</td>
                              <td className="bs table-cell col-5">{element.DomainClass}</td>
                              <td className="bs table-cell col-5">{element.DomainMainSegment}</td>
                              <td className="bs table-cell col-5">{element.VisitorRange}</td>
                              <td className="bs table-cell col-5">{element.SocialNetworks}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {lawsuits && (
            <Box className="detail-group">
              <h3 className="detail-group-title">
                {t('common:lawsuits')} ({t('common:shareholders')})
              </h3>
              {renderCheckGeneralDetails(kyb?.lawsuits)}
              {!Array.isArray(lawsuitsCheck) && Object.keys(lawsuitsCheck)?.length && (
                <Box>
                  <Box>
                    <span>{t('common:information')}</span>
                    <p>{lawsuitsCheck.info ?? '---'}</p>
                  </Box>
                </Box>
              )}
              {Array.isArray(lawsuitsCheck) && lawsuitsCheck.length && (
                <Box>
                  <h4>{t('common:sanctions')}</h4>
                  <Box className="group-table">
                    <table className="bs bst group-table">
                      <thead>
                        <tr className="group-table">
                          <th className="bs">{t('common:firstLawsuitDate')}</th>
                          <th className="bs">{t('common:lastLawsuitDate')}</th>
                          <th className="bs">
                            {t('common:last90')} {t('common:lawsuits')}
                          </th>
                          <th className="bs">{t('common:averagePerOwner')}</th>
                          <th className="bs">{t('common:activeOwners')}</th>
                          <th className="bs">{t('common:total')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lawsuitsCheck.map((element, index) => {
                          return (
                            <tr className="group-table" key={index}>
                              <td className="bs table-cell col-4">
                                {element.OwnersLawsuits?.FirstLawsuitDate ?? '---'}
                              </td>
                              <td className="bs table-cell col-4">
                                {element.OwnersLawsuits?.LastLawsuitDate ?? '---'}
                              </td>
                              <td className="bs table-cell col-4">
                                {element.OwnersLawsuits?.Last90DaysLawsuits ?? '---'}
                              </td>
                              <td className="bs table-cell col-4">
                                {element.OwnersLawsuits?.AverageLawsuitsPerOwner ?? '---'}
                              </td>
                              <td className="bs table-cell col-4">
                                {element.OwnersLawsuits?.ActiveOwners?.length ?? '---'}
                              </td>
                              <td className="bs table-cell col-4">{element.OwnersLawsuits?.TotalLawsuits ?? '---'}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {kyc && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:kyc')}</h3>
              {renderCheckGeneralDetails(kyb?.kyc)}
              {Object.keys(kycCheck).length && (
                <Box className="">
                  <h4>
                    {t('common:kyc')} {t('common:details')}
                  </h4>
                  <Box className="detail-group-grid-4">
                    <Box>
                      <span>{t('common:isCurrentlyPep')}</span>
                      <p>{kycCheck.IsCurrentlyPEP ? 'Yes' : 'No'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:isCurrentlySanctioned')}</span>
                      <p>{kycCheck.IsCurrentlySanctioned ? 'Yes' : 'No'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:wasPreviouslySanctioned')}</span>
                      <p>{kycCheck.WasPreviouslySanctioned ? 'Yes' : 'No'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last30')} {t('common:sanctions')}
                      </span>
                      <p>{kycCheck.Last30DaysSanctions ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last90')} {t('common:sanctions')}
                      </span>
                      <p>{kycCheck.Last90DaysSanctions ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last180')} {t('common:sanctions')}
                      </span>
                      <p>{kycCheck.Last180DaysSanctions ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:last365')} {t('common:sanctions')}
                      </span>
                      <p>{kycCheck.Last365DaysSanctions ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:lastYearPEPOccurence')}</span>
                      <p>{kycCheck.LastYearPEPOccurence ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:last3YearsPEPOccurence')}</span>
                      <p>{kycCheck.Last3YearsPEPOccurence ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:last5YearsPEPOccurence')}</span>
                      <p>{kycCheck.Last5YearsPEPOccurence ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:last5PlusYearsPEPOccurence')}</span>
                      <p>{kycCheck.Last5PlusYearsPEPOccurence ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:firstSanctionDate')}</span>
                      <p>{!kycCheck.FirstSanctionDate?.includes('0001-01-01') ? kycCheck.FirstSanctionDate : '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:lastSanctionDate')}</span>
                      <p>{!kycCheck.LastSanctionDate?.includes('0001-01-01') ? kycCheck.LastSanctionDate : '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:isCurrentlyElectoralDonor')}</span>
                      <p>{kycCheck.IsCurrentlyElectoralDonor ? 'Yes' : 'No'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:isHistoricalElectoralDonor')}</span>
                      <p>{kycCheck.IsHistoricalElectoralDonor ? 'Yes' : 'No'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:total')} {t('common:electoralDonations')} {t('common:lastTwoElections')}
                      </span>
                      <p>{kycCheck.TotalElectoralDonationsLastTwoElections ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:total')} {t('common:electoralDonations')}
                      </span>
                      <p>{kycCheck.TotalElectoralDonations ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:total')} {t('common:electoralDonations')} {t('common:amount')}{' '}
                        {t('common:lastTwoElections')}
                      </span>
                      <p>{kycCheck.TotalElectoralDonationAmountLastTwoElections ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:total')} {t('common:electoralDonations')} {t('common:amount')}
                      </span>
                      <p>{kycCheck.TotalElectoralDonationAmount ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {cdn && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:negativeDebtCertificate')}</h3>
              {renderCheckGeneralDetails(kyb?.cdn)}
              {Object.keys(cdnCheck).length && (
                <Box className="">
                  <h4>
                    {t('common:negativeDebtCertificate')} {t('common:details')}
                  </h4>
                  <Box className="detail-group-grid-4">
                    <Box>
                      <span>{t('common:message')}</span>
                      <p>{cdnCheck.Mensagem ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:taxpayerType')}</span>
                      <p>{cdnCheck.Certidao?.TipoContribuinte ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:taxpayerCertificate')}</span>
                      <p>{cdnCheck.Certidao?.ContribuinteCertidao ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:certificateType')}</span>
                      <p>{cdnCheck.Certidao?.TipoCertidao ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:controlCode')}</span>
                      <p>{cdnCheck.Certidao?.CodigoControle ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:issuanceDate')}</span>
                      <p>{cdnCheck.Certidao?.DataEmissao ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:expirationDate')}</span>
                      <p>{cdnCheck.Certidao?.DataValidade ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {negativeMedia && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:negativeMedia')}</h3>
              {renderCheckGeneralDetails(kyb?.negativeMedia)}
              {Object.keys(negativeMediaCheck).length && (
                <Box className="">
                  <h4>{t('common:negativeMediaDetails')}</h4>
                  <Box className="detail-group-grid-3">
                    <Box>
                      <span>{t('common:mediaExposureLevel')}</span>
                      <p>{negativeMediaCheck.MediaExposureLevel ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:celebrityLevel')}</span>
                      <p>{negativeMediaCheck.CelebrityLevel ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:unpopularityLevel')}</span>
                      <p>{negativeMediaCheck.UnpopularityLevel ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:totalNews')}</span>
                      <p>{negativeMediaCheck.EntityStatistics?.NewsByRangeDate?.TotalNews ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalNews')} {t('common:last7')}
                      </span>
                      <p>{negativeMediaCheck.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast7Days ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalNews')} {t('common:last30')}
                      </span>
                      <p>{negativeMediaCheck.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast30Days ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalNews')} {t('common:last90')}
                      </span>
                      <p>{negativeMediaCheck.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast90Days ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalNews')} {t('common:last180')}
                      </span>
                      <p>{negativeMediaCheck.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast180Days ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>
                        {t('common:totalNews')} {t('common:last365')}
                      </span>
                      <p>{negativeMediaCheck.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast365Days ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {serasa && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:serasaScore')}</h3>
              {renderCheckGeneralDetails(kyb?.serasa)}
              {Object.keys(serasaCheck).length && (
                <Box>
                  <h4>{t('common:risk')}</h4>
                  <Box className="detail-group-grid-3">
                    <Box>
                      <span>{t('common:level')}</span>
                      <p>{serasaCheck.risco?.nivel ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:description')}</span>
                      <p>{serasaCheck.risco?.descricao ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:score')}</span>
                      <p>{serasaCheck.risco?.score ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {assignedUsers && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:assignedUsers')}</h3>
              {kyb?.shareHolders && (
                <Box className="group-table">
                  <table className="bs bst group-table">
                    <thead>
                      <tr className="group-table">
                        <th className="bs">email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb?.assignedUsers?.map((element, index) => {
                        return (
                          <tr className="group-table" key={index}>
                            <td className="bs table-cell col-1">{element?.email}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
          {timeline && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:timelineAndActivity')}</h3>
              {kyb?.timeline && (
                <Box>
                  <table className="bs bst group-table">
                    <thead>
                      <tr>
                        <th className="bs">{t('common:email')}</th>
                        <th className="bs">{t('common:type')}</th>
                        <th className="bs">{t('common:description')}</th>
                        <th className="bs">{t('common:status')}</th>
                        <th className="bs">{t('common:date')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb.timeline.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td className="bs table-cell col-5">{element.email}</td>
                            <td className="bs table-cell col-5">{element.type}</td>
                            <td className="bs table-cell col-5">{element.description}</td>
                            <td className="bs table-cell col-5">{element.status}</td>
                            <td className="bs table-cell col-5">{element.createdAt}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default KybToPdf
